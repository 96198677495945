import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

const WeeklyAppraisals = () => {
    const { name } = useParams();
    const navigate = useNavigate();
    const [ratings, setRatings] = useState({
        salesTargets: 0,
        clientManagement: 0,
        productKnowledge: 0,
        proactiveApproach: 0,
        teamCollaboration: 0,
        focusAndCalls: 0,
        adaptability: 0,
        followUpConsistency: 0,
        salesTechniques: 0,
        feedbackUtilisation: 0,
        effectiveCommunication: 0,
        weeklyCalls: 0,
    });

    const [comments, setComments] = useState({});
    const [overallComments, setOverallComments] = useState("");
    const [date, setDate] = useState("");  // New state for the date input

    const handleRatingChange = (category, value) => {
        setRatings((prevRatings) => ({
            ...prevRatings,
            [category]: value,
        }));
    };

    const handleCommentChange = (category, value) => {
        setComments((prevComments) => ({
            ...prevComments,
            [category]: value,
        }));
    };

    const calculateTotalRating = () => {
        return Object.values(ratings).reduce((a, b) => a + b, 0);
    };

    const submitAppraisal = async () => {
        try {
            const totalRating = calculateTotalRating();
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/v3/weekly-appraisals/${name}`, {
                ...ratings,
                comments,
                overallComments,
                totalRating,
                date,  // Include the date in the data sent to the backend
            });
            alert(response.data.message || 'Appraisal submitted successfully');
            navigate(-1)
        } catch (error) {
            console.error('Error submitting weekly appraisal:', error);
            alert('Failed to submit appraisal. Please try again.');
        }
    };

    const categories = [
        { name: 'Achievement of Sales Targets', key: 'salesTargets' },
        { name: 'Client Relationship Management', key: 'clientManagement' },
        { name: 'Product Knowledge', key: 'productKnowledge' },
        { name: 'Proactive Approach', key: 'proactiveApproach' },
        { name: 'Team Collaboration', key: 'teamCollaboration' },
        { name: 'Focus and Number of Calls', key: 'focusAndCalls' },
        { name: 'Adaptability', key: 'adaptability' },
        { name: 'Follow-Up Consistency', key: 'followUpConsistency' },
        { name: 'Sales Techniques', key: 'salesTechniques' },
        { name: 'Feedback Utilisation', key: 'feedbackUtilisation' },
        { name: 'Effective Communication', key: 'effectiveCommunication' },
        { name: 'Weekly Calls', key: 'weeklyCalls' },
    ];

    return (
        <div className="p-6">
            <div className='flex justify-between items-center'>
                <h1 className="text-2xl font-bold mb-4">Weekly Appraisal for {name}</h1>
                <div className="mb-4">
                    <label className="font-semibold mr-3">Appraisal Date:</label>
                    <input
                        type="date"
                        value={date}
                        onChange={(e) => setDate(e.target.value)}
                        className="input input-bordered"
                        required
                    />
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                {categories.map((category) => (
                    <div key={category.key} className="mb-4">
                        <h2 className="font-semibold">{category.name}</h2>
                        <div className="flex space-x-2 mt-2">
                            {[1, 2, 3, 4, 5].map((value) => (
                                <label key={value} className="flex items-center">
                                    <input
                                        type="radio"
                                        name={category.key}
                                        value={value}
                                        checked={ratings[category.key] === value}
                                        onChange={() => handleRatingChange(category.key, value)}
                                        className="form-radio h-5 w-5 text-blue-600"
                                    />
                                    <span className="ml-1">{value}</span>
                                </label>
                            ))}
                        </div>
                        <textarea
                            placeholder="Comments"
                            value={comments[category.key] || ""}
                            onChange={(e) => handleCommentChange(category.key, e.target.value)}
                            className="w-full mt-2 p-2 border border-base-300 rounded-lg"
                        ></textarea>
                    </div>
                ))}
            </div>

            <div className="mt-6">
                <h2 className="font-bold">Overall Assessment:</h2>
                <p>Total Rating: {calculateTotalRating()}</p>
                <textarea
                    placeholder="Overall Comments"
                    value={overallComments}
                    onChange={(e) => setOverallComments(e.target.value)}
                    className="w-full mt-2 p-2 border border-base-300 rounded-lg"
                ></textarea>
            </div>

            <div className='text-right'>
                <button
                    onClick={submitAppraisal}
                    className="button mt-3"
                >
                    Submit Appraisal
                </button>
            </div>
        </div>
    );
};

export default WeeklyAppraisals;
