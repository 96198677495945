import React, { useContext } from 'react';
import { doc, setDoc } from "firebase/firestore";
import { db } from '../../firebase/firebase.config';
import { AuthContext } from '../../context/AuthContext';
import axios from 'axios';

const EditUser = () => {
    const { user } = useContext(AuthContext);


    const addNewUser = async (e) => {
        e.preventDefault();

        console.log('added');

        // Extract form data
        const formData = new FormData(e.target);
        const displayName = formData.get('name');
        const designation = formData.get('designation');
        const email = formData.get('email');
        const password = formData.get('password');
        const joiningDate = formData.get('joiningDate');
        const personalEmail = formData.get('personalEmail');
        const phone = formData.get('phone');

        // Prepare the user object
        const newUser = {
            displayName,
            designation,
            email,
            password,
            joiningDate,
            personalEmail,
            phone,
            userEmail: user.email
        };

        try {
            // Send POST request to your backend API using Axios
            const token = localStorage.getItem('token');
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/create-user`, newUser, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            // Handle success
            console.log('User added successfully:', response.data);
        } catch (error) {
            // Handle error
            console.error('Error adding user:', error);
        }
    }

    return (
        <div className='flex gap-7 mt-7'>
            <div className='basis-1/3 bg-base-200 p-7 rounded-lg'>
                <img className='w-28 rounded-full' src="https://yt3.googleusercontent.com/g3j3iOUOPhNxBCNAArBqiYGzHzCBIzr_Al8mdvtBJeZMGFDblnU5rlVUt6GY01AUwm7Cp70J=s900-c-k-c0x00ffffff-no-rj" alt="profile_pic" />
                <p className='font-semibold text-2xl mt-3'>Oggy Oliver</p>
                <p>Cartoon Charecter</p>

                <p className='mt-3'><span className='font-semibold'>Joining Date: </span>19th May, 2024</p>

                <p className='font-semibold mt-4'>Phone: +42380933742</p>
                <p className='font-semibold'>Email: oggy@cartoon.com</p>

            </div>
            <form onSubmit={(e) => addNewUser(e)} className='basis-2/3 bg-base-200 p-7 rounded-lg'>
                <div className='flex justify-between items-center'>
                    <p className='text-2xl font-bold'>Information:</p>
                </div>
                <div className='mt-5'>
                    <div className='flex items-center'>
                        <label htmlFor="name" className=' text-lg font-semibold mb-1 basis-1/4'>Name:</label>
                        <input name='name' type="text" placeholder="Example: Captain America" className="input basis-3/4" />
                    </div>
                    <div className='flex items-center mt-4'>
                        <label htmlFor="designation" className=' text-lg font-semibold mb-1 basis-1/4'>Designation:</label>
                        <input name='designation' type="text" placeholder="Example: Captain of the Avengers" className="input basis-3/4" />
                    </div>
                    <div className='flex items-center mt-4'>
                        <label htmlFor="email" className=' text-lg font-semibold mb-1 basis-1/4'>Email:</label>
                        <input name='email' type="text" placeholder="Example: captainamerica@gmail.com" className="input basis-3/4" />
                    </div>
                    <div className='flex items-center mt-4'>
                        <label htmlFor="password" className=' text-lg font-semibold mb-1 basis-1/4'>Password:</label>
                        <input name='password' type="password" placeholder="Example: captainamerica@gmail.com" className="input basis-3/4" />
                    </div>
                    <div className='flex items-center mt-4'>
                        <label htmlFor="joiningDate" className=' text-lg font-semibold mb-1 basis-1/4'>Joining Date:</label>
                        <input name='joiningDate' type="date" className="input basis-3/4" />
                    </div>
                    <div className='flex items-center mt-4'>
                        <label htmlFor="personalEmail" className=' text-lg font-semibold mb-1 basis-1/4'>Personal Email:</label>
                        <input name='personalEmail' type="email" className="input basis-3/4" />
                    </div>
                    <div className='flex items-center mt-4'>
                        <label htmlFor="phone" className=' text-lg font-semibold mb-1 basis-1/4'>Phone:</label>
                        <input name='phone' type="text" className="input basis-3/4" />
                    </div>
                </div>
                <div className='mt-5'>
                    <button type='submit' className='btn bg-primary text-white'>Update Profile</button>
                </div>
            </form>
        </div>
    )
}

export default EditUser
