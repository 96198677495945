import React, { useContext, useEffect, useRef, useState } from 'react'
import RequestLoanPopup from '../../components/RequestLoanPopup/RequestLoanPopup';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import {
    ref,
    uploadBytes,
    getDownloadURL,
} from "firebase/storage";
import { storage } from '../../firebase/firebase.config';
import EditLoanCustomer from '../../components/EditLoanCustomer/EditLoanCustomer';


const CustomerDashboard = () => {
    const formRef = useRef(null);

    const [editLoan, setEditLoan] = useState({});
    const [refresh, setRefresh] = useState(true);

    const [allpdf, setAllpdf] = useState([]);
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);

    const [loanList, setLoanList] = useState([]);
    const [loantype, setLoantype] = useState("");
    const navigate = useNavigate();
    const { user, loading, country, setCountry } = useContext(AuthContext);

    // open stl modal
    const openStl = () => {
        setLoantype("MCA")
        window.stl.showModal()
    }
    const handleLtl = () => {
        setLoantype("Business Loan")
        window.ltl.showModal()
    };
    const handleIfinance = () => {
        setLoantype("Invoice Finance")
        window.invoiceF.showModal()
    };
    const handleInvestments = () => {
        setLoantype("Grants")
        window.investment.showModal()
    };

    const handleEditLoan = (item) => {
        setEditLoan(item)
        window.editLoan.showModal()
    };
    const handleUploadPDF = (item) => {
        setEditLoan(item)
        window.uploadpdf.showModal()
    };


    const closeModal = (type) => {
        const dialog = document.getElementById(type);
        dialog.close();
    }

    const onPdfSubmit = (e) => {
        e.preventDefault()
        if (allpdf.length !== 0) {
            const files = allpdf;
            const fileUploadPromises = [];

            for (const file of files) {
                const pdfREF = ref(storage, `pdf/${editLoan?._id}/${file.name}`);
                const uploadPromise = uploadBytes(pdfREF, file);
                fileUploadPromises.push(uploadPromise);
            }

            setAllpdf([]);
            document.getElementById("uploadpdf").close()
        }
    }


    const logs = (e, f) => {
        e.preventDefault()
        if (loantype === "MCA") {
            const dialog = document.getElementById(f);
            const loanData = {
                "business-type": e?.target?.businessType?.value,
                "business-name": e?.target?.businessName?.value,
                "business-contact-name": e?.target?.contactName?.value,
                "business-phone": e?.target?.phone?.value,
                "business-email": e?.target?.email?.value,
                "business-address": e?.target?.businessAddress?.value,
                "business-chip": e?.target?.chipPin?.value,
                "business-turnover": e?.target?.businessTurnover?.value,
                "business-invoice": e?.target?.invoice?.value,
                "business-notes": e?.target?.notes?.value,
                "required-amount": e?.target?.requiredAmount?.value,
                "user": user.email,
                "status": "Initial Review",
                "type": loantype,
                "country": country,
                "createdDate": new Date().toISOString()
            };
            fetch(`${process.env.REACT_APP_SERVER_URL}/loan-request`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(loanData)
            })
                .then(response => response.json())
                .then(result => {
                    // upload pdf with inserted id
                    // console.log(allpdf);
                    if (allpdf.length !== 0) {
                        const files = allpdf;
                        const fileUploadPromises = [];

                        console.log(files);

                        for (const file of files) {
                            const pdfREF = ref(storage, `pdf/${result?.insertedId}/${file.name}`);
                            const uploadPromise = uploadBytes(pdfREF, file);
                            fileUploadPromises.push(uploadPromise);
                        }

                        setAllpdf([]);
                    }
                    e.target.reset();
                    loanData._id = result?.insertedId;
                    setLoanList([...loanList, loanData]);
                    dialog.close();
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
        else if (loantype === "Business Loan") {
            const dialog = document.getElementById(f);
            const loanData = {
                "business-type": e?.target?.businessType?.value,
                "business-name": e?.target?.businessName?.value,
                "business-contact-name": e?.target?.contactName?.value,
                "business-phone": e?.target?.phone?.value,
                "business-email": e?.target?.email?.value,
                "business-address": e?.target?.businessAddress?.value,
                "business-turnover": e?.target?.businessTurnover?.value,
                "business-invoice": e?.target?.invoice?.value,
                "business-notes": e?.target?.notes?.value,
                "required-amount": e?.target?.requiredAmount?.value,
                "user": user.email,
                "status": "Initial Review",
                "type": loantype,
                "country": "£",
                "createdDate": new Date().toISOString()
            };
            fetch(`${process.env.REACT_APP_SERVER_URL}/loan-request`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(loanData)
            })
                .then(response => response.json())
                .then(result => {

                    // upload pdf with inserted id
                    if (allpdf.length !== 0) {
                        const files = allpdf;
                        const fileUploadPromises = [];

                        for (const file of files) {
                            const pdfREF = ref(storage, `pdf/${result?.insertedId}/${file.name}`);
                            const uploadPromise = uploadBytes(pdfREF, file);
                            fileUploadPromises.push(uploadPromise);
                        }

                        setAllpdf([]);
                    }
                    e.target.reset();
                    loanData._id = result?.insertedId;
                    setLoanList([...loanList, loanData]);
                    dialog.close();
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }

        else if (loantype === "Invoice Finance") {
            const dialog = document.getElementById(f);
            const loanData = {
                "business-type": e?.target?.businessType?.value,
                "business-name": e?.target?.businessName?.value,
                "business-contact-name": e?.target?.contactName?.value,
                "business-phone": e?.target?.phone?.value,
                "business-email": e?.target?.email?.value,
                "business-address": e?.target?.businessAddress?.value,
                "business-turnover": e?.target?.businessTurnover?.value,
                "business-invoice": e?.target?.invoice?.value,
                "business-notes": e?.target?.notes?.value,
                "required-amount": e?.target?.requiredAmount?.value,
                "user": user.email,
                "status": "Initial Review",
                "type": loantype,
                "country": "£",
                "createdDate": new Date().toISOString()
            };
            fetch(`${process.env.REACT_APP_SERVER_URL}/loan-request`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(loanData)
            })
                .then(response => response.json())
                .then(result => {
                    // upload pdf with inserted id
                    if (allpdf.length !== 0) {
                        const files = allpdf;
                        const fileUploadPromises = [];

                        for (const file of files) {
                            const pdfREF = ref(storage, `pdf/${result?.insertedId}/${file.name}`);
                            const uploadPromise = uploadBytes(pdfREF, file);
                            fileUploadPromises.push(uploadPromise);
                        }

                        setAllpdf([]);
                    }
                    e.target.reset();
                    loanData._id = result?.insertedId;
                    setLoanList([...loanList, loanData]);
                    dialog.close();
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
        else if (loantype === "Grants") {
            const dialog = document.getElementById(f);
            const loanData = {
                "business-type": e?.target?.businessType?.value,
                "business-name": e?.target?.businessName?.value,
                "business-contact-name": e?.target?.contactName?.value,
                "business-phone": e?.target?.phone?.value,
                "business-email": e?.target?.email?.value,
                "trading-duration": e?.target?.tradingTime?.value,
                "business-notes": e?.target?.notes?.value,
                "required-amount": e?.target?.requiredAmount?.value,
                "user": user.email,
                "status": "Initial Review",
                "country": "£",
                "type": loantype,
                "createdDate": new Date().toISOString()
            };
            fetch(`${process.env.REACT_APP_SERVER_URL}/loan-request`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(loanData)
            })
                .then(response => response.json())
                .then(result => {
                    e.target.reset();
                    loanData._id = result?.insertedId;
                    setLoanList([...loanList, loanData]);
                    dialog.close();
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }
    const token = localStorage.getItem('token')
    useEffect(() => {
        
        if (!loading && token !== null) {
            fetch(`${process.env.REACT_APP_SERVER_URL}/customer/loan-request/GETALL`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    authorization: `Bearer ${localStorage.getItem('token')}`
                },
                body: JSON.stringify({ email: user.email })
            })
                .then(response => response.json())
                .then(data => {
                    setLoanList(data);
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }, [loading, refresh, user, token])

    const rows = ["COMPANY NAME", "CREATED", "REQUIREMENT", "FUNDED AMOUNT", "TYPE", "STATUS", "FUNDING MANAGER", "6 Months Bank"];

    const searchByName = (e) => {
        const searchName = e.target.value;
        const url = `${process.env.REACT_APP_SERVER_URL}/search?name=${encodeURIComponent(searchName)}`;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                let tempLoanList = [];
                data.map(item => {
                    if (item.user === user.email) {
                        tempLoanList = [...tempLoanList, item];
                    }
                })

                setLoanList(tempLoanList);
            })
            .catch(error => {
                console.error('Error:', error);
                // Handle the error or display an error message
            });
    }

    const searchByStatus = (e) => {
        setLoanList([])
        const searchName = e.target.value;
        const url = `${process.env.REACT_APP_SERVER_URL}/status?status=${encodeURIComponent(searchName)}`;

        fetch(url)
            .then(response => response.json())
            .then(data => {
                // Call a function to handle the search results
                // setSearchResults(data);
                let tempLoanList = [];
                data.map(item => {
                    if (item.user === user.email) {
                        tempLoanList = [...tempLoanList, item];
                    }
                })
                setLoanList(tempLoanList);
            })
            .catch(error => {
                console.error('Error:', error);
                // Handle the error or display an error message
            });
    }

    const deleteRequest = async (id) => {
        try {
            await fetch(`${process.env.REACT_APP_SERVER_URL}/loan-request/${id}`, {
                method: 'DELETE',
            });
            setRefresh(!refresh); // Refresh the loan requests after deletion
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const setCurrency = (e) => {
        setCountry(e.target.value)
    }




    function selectFiles() {
        fileInputRef.current.click();
    }

    function onFileSelect(event) {
        const files = event.target.files;
        setAllpdf([...files])
        if (files.length === 0) return;
        for (let i = 0; i < files.length; i++) {
        }
    }

    const removeFromUpload = (i) => {

    }

    const onDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
        e.dataTransfer.dropEffect = "copy";
    }
    const onDragLeave = (e) => {
        e.preventDefault();
        setIsDragging(false);
    }
    const onDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        const files = e.dataTransfer.files;
        setAllpdf([...files])
    }



    return (
        <div className='m-5 md:mb-0 md:ml-0 md:mt-10 md:mr-10'>
            <div className='md:flex justify-between items-center'>
                <input onChange={(e) => searchByName(e)} name='search' type="text" placeholder="Search" className="input w-full md:w-72" />
                <div>
                    <select onChange={searchByStatus} name='status' className="input mr-3 mt-3 md:mt-0 w-full md:w-auto">
                        <option value="all" defaultValue>ALL</option>
                        <option value="Initial Review">Initial Review</option>
                        <option value="Additional Documents">Additional Documents</option>
                        <option value="Underwriting">Underwriting</option>
                        <option value="Offer">Offer</option>
                        <option value="Contract Sent">Contract Sent</option>
                        <option value="Funded">Funded</option>
                        <option value="Cancelled">Cancelled</option>
                        <option value="Start Review">Start Review</option>
                        <option value="Awaiting Start Payment">Awaiting Start Payment</option>
                        <option value="Pitch Creation">Pitch Creation</option>
                        <option value="Online Posted">Online Posted</option>
                        <option value="Sent to Larger Investors">Sent to Larger Investors</option>
                        <option value="Awaiting Payment">Awaiting Payment</option>
                    </select>
                    <button onClick={() => window.my_modal_1.showModal()} className="button mt-3 md:mt-0 w-full md:w-auto">Request Finance</button>
                </div>
            </div>

            <div style={{ boxShadow: "0 20px 27px 0 rgba(0, 0, 0, 0.05)" }} className='mt-5 rounded-lg bg-[#fff] '>
                <div className="overflow-x-auto p-3">
                    <table className="table">
                        {/* head */}
                        <thead>
                            <tr>
                                {
                                    rows.map((item, i) => <th key={i} scope="col">
                                        {item}
                                    </th>)
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {loanList &&
                                loanList.map((item, i) => <tr key={i}>
                                    <th>
                                        {item["business-name"]}
                                    </th>
                                    <td >
                                        {new Date(item?.createdDate).toLocaleDateString('en-UK', {
                                            day: 'numeric',
                                            month: 'numeric',
                                            year: 'numeric'
                                        })}
                                    </td>
                                    <td >
                                        {item["country"]}  {parseInt(item["required-amount"]).toLocaleString('en-US', { style: 'decimal', useGrouping: true }) || '0'}
                                    </td>
                                    <td >
                                        {item["country"]}  {parseInt(item["funded-amount"]).toString() !== "NaN"
                                            ? parseInt(item["funded-amount"]).toLocaleString('en-US', { style: 'decimal', useGrouping: true })
                                            : '0'}
                                    </td>
                                    <td >
                                        {item["type"]}
                                    </td>
                                    <td>
                                        {item["status"] || 'Initial Review'}
                                    </td>
                                    <td>
                                        {item["funding-manager"] || 'Pending'}
                                    </td>
                                    <td className='flex items-center'>
                                        <button onClick={() => handleUploadPDF(item)} className='btn btn-xs'>UPLOAD</button>
                                        {/* <span onClick={() => deleteRequest(item._id)} className='ml-4 cursor-pointer text-[#e63a27] text-2xl'><HiTrash></HiTrash></span> */}
                                    </td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <dialog id="editLoan" className="modal w-[100%] max-w-[100%] mx-auto rounded">
                <div method="dialog" className="modal-box w-[900px] max-w-[900px] p-10">
                    <EditLoanCustomer item={editLoan} setRefresh={setRefresh} refresh={refresh} closeModal={closeModal} />
                </div>
            </dialog>
            <dialog id="uploadpdf" className="modal w-[100%] max-w-[100%] mx-auto rounded">
                <div method="dialog" className="modal-box w-[500px] max-w-[500px] p-10">
                    <form onSubmit={(e)=>onPdfSubmit(e)}>
                        <div className="ocard">
                            <div className="drag-area" onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop}>
                                {
                                    isDragging ? (
                                        <span className="oselect">Drop PDF here</span>
                                    ) : (
                                        <>
                                            Drag & Drop PDF here or {""}
                                            <span className="oselect" role='button' onClick={selectFiles}>
                                                Browse
                                            </span>
                                        </>
                                    )
                                }


                                <input id='mypdf' type="file" name='file' className='ofile' multiple ref={fileInputRef} onChange={onFileSelect} />
                            </div>
                            <div className="ocontainer">
                                {
                                    allpdf.map((item, i) => (
                                        <div className="pdf text-sm" key={i}>
                                            {item.name}
                                            <span onClick={() => removeFromUpload(i)} className='delete'>&times;</span>
                                        </div>
                                    ))
                                }

                            </div>
                        </div>
                        <div className='flex justify-between mt-3'>
                            <button type='button' onClick={() => closeModal("uploadpdf")} className="button !text-[#000] !bg-[#f2f2f2] ">Close</button>
                            <button type='submit' className='button rounded'>submit</button>
                        </div>
                    </form>
                </div>
            </dialog>
            <dialog id="my_modal_1" className="modal w-[400px] mx-auto rounded">
                <form method="dialog" className="modal-box">
                    <RequestLoanPopup
                        handleStl={openStl}
                        handleLtl={handleLtl}
                        handleIfinance={handleIfinance}
                        handleInvestments={handleInvestments}
                    />
                    <div className="modal-action">
                        {/* if there is a button in form, it will close the modal */}
                        <button className="btn">Close</button>
                    </div>
                </form>
            </dialog>
            <dialog id="stl" className="modal w-[900px] max-w-[900px] mx-auto">
                <form id='short' ref={formRef} onSubmit={(e) => logs(e, "stl")} method="dialog" className="modal-box p-10 w-[700px] max-w-[700px] mx-auto">
                    <div className='grid grid-cols-2  gap-3'>
                        <select onChange={setCurrency} name='country' className="input w-auto">
                            <option value="£" defaultValue>£ - United Kingdom</option>
                            <option value="$" >$ - USA</option>
                            <option value="€" >€ - Belgium</option>
                            <option value="€" >€ - Germany</option>
                            <option value="€" >€ - Ireland</option>
                            <option value="€" >€ - Netherlands</option>
                            <option value="€" >€ - Poland</option>
                            <option value="€" >€ - Spain</option>
                        </select>
                        <select name='businessType' className="input" required>
                            <option value="" disabled defaultValue>Company Type</option>
                            <option value="Ltd">Ltd</option>
                            <option value="PLC">PLC</option>
                            <option value="LLP">LLP</option>
                            <option value="Sole Trader">Sole Trader</option>
                            <option value="Partnership">Partnership</option>
                        </select>
                        <input name='businessName' type="text" placeholder="Business Name" className="input" required />
                        <input name='contactName' type="text" placeholder="Contact Name" className="input" required />
                        <input name='phone' type="text" placeholder="Phone Number" className="input" required />
                        <input name='email' type="text" placeholder="Email" className="input" required />
                        <input name='businessAddress' type="text" placeholder="Business Address" className="input" required />
                        <input name='chipPin' type="text" placeholder="Chip & Pin monthly" className="input" required />
                        <input name='businessTurnover' type="text" placeholder="Business Turnover Monthly" className="input" required />
                        <input name='invoice' type="text" placeholder="Do they Invoice" className="input" required />
                    </div>

                    {/* <div className='mt-1'>
                        <label className='label'>Bank Statement</label>
                        <input onChange={(e) => { setPdfUpload(e.target.files[0]) }} type="file" className="file-input file-input-bordered file-input-sm w-full" />
                    </div> */}
                    <input name='requiredAmount' type="text" placeholder="Required Amount" className="input w-full mt-3" required />
                    <input name='notes' type="text" placeholder="Notes" className="input w-full mt-3" />
                    <p className='mt-4 text-sm font-bold'>Add your 6 months bank statements:</p>
                    <div className="ocard">
                        <div className="drag-area" onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop}>
                            {
                                isDragging ? (
                                    <span className="oselect">Drop PDF here</span>
                                ) : (
                                    <>
                                        Drag & Drop PDF here or {""}
                                        <span className="oselect" role='button' onClick={selectFiles}>
                                            Browse
                                        </span>
                                    </>
                                )
                            }


                            <input id='mypdf' type="file" name='file' className='ofile' multiple ref={fileInputRef} onChange={onFileSelect} />
                        </div>
                        <div className="ocontainer">
                            {
                                allpdf.map((item, i) => (
                                    <div className="pdf text-sm" key={i}>
                                        {item.name}
                                        <span onClick={() => removeFromUpload(i)} className='delete'>&times;</span>
                                    </div>
                                ))
                            }

                        </div>
                    </div>
                    <div className='flex gap-3 mt-4'>
                        <button type='button' onClick={() => closeModal("stl")} className="basis-1/2 button !text-[#000] !bg-[#f2f2f2] !rounded">Close</button>
                        <button type='submit' className='button basis-1/2 !rounded'>submit</button>
                    </div>
                </form>
            </dialog>
            <dialog id="ltl" className="modal w-[900px] max-w-[900px] mx-auto">
                <form ref={formRef} onSubmit={(e) => logs(e, "ltl")} method="dialog" className="modal-box p-10 w-[800px] max-w-[800px] mx-auto">
                    <div className='grid grid-cols-2  gap-3'>
                        <select name='businessType' className="input" required>
                            <option value="" disabled defaultValue>Company Type</option>
                            <option value="Ltd">Ltd</option>
                            <option value="PLC">PLC</option>
                            <option value="LLP">LLP</option>
                            <option value="Sole Trader">Sole Trader</option>
                            <option value="Partnership">Partnership</option>
                        </select>
                        <input name='businessName' type="text" placeholder="Business Name" className="input" required />
                        <input name='contactName' type="text" placeholder="Contact Name" className="input" required />
                        <input name='phone' type="text" placeholder="Phone Number" className="input" required />
                        <input name='email' type="text" placeholder="Email" className="input" required />
                        <input name='businessAddress' type="text" placeholder="Business Address" className="input" required />
                        <input name='businessTurnover' type="text" placeholder="Business Turnover Monthly" className="input" required />
                        <input name='invoice' type="text" placeholder="Do they Invoice" className="input" required />
                    </div>
                    <input name='requiredAmount' type="text" placeholder="Required Amount" className="input mt-3 w-full" required />
                    {/* <div className='flex gap-3'>
                        <div>
                            <label className='label text-sm'>Bank Statement</label>
                            <input onChange={(e) => { setPdfUpload(e.target.files[0]) }} type="file" className="file-input file-input-bordered file-input-sm w-full" />
                        </div>
                        <div>
                            <label className='label text-sm'>EYA Statement</label>
                            <input onChange={(e) => { setEyaUpload(e.target.files[0]) }} type="file" className="file-input file-input-bordered file-input-sm w-full" />
                        </div>
                        <div>
                            <label className='label text-sm'>Leatest Management Accoutns</label>
                            <input onChange={(e) => { setLeatestManagementAccoutns(e.target.files[0]) }} type="file" className="file-input file-input-bordered file-input-sm w-full" />
                        </div>
                    </div> */}
                    <input name='notes' type="text" placeholder="Notes" className="input w-full mt-3" />
                    <p className='mt-4 text-sm font-bold'>Add your 6 months Bank Statements, End of Year Accounts, Management Accounts:</p>
                    <div className="ocard">
                        <div className="drag-area" onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop}>
                            {
                                isDragging ? (
                                    <span className="oselect">Drop PDF here</span>
                                ) : (
                                    <>
                                        Drag & Drop PDF here or {""}
                                        <span className="oselect" role='button' onClick={selectFiles}>
                                            Browse
                                        </span>
                                    </>
                                )
                            }


                            <input id='mypdf' type="file" name='file' className='ofile' multiple ref={fileInputRef} onChange={onFileSelect} />
                        </div>
                        <div className="ocontainer">
                            {
                                allpdf.map((item, i) => (
                                    <div className="pdf text-sm" key={i}>
                                        {item.name}
                                        <span onClick={() => removeFromUpload(i)} className='delete'>&times;</span>
                                    </div>
                                ))
                            }

                        </div>
                    </div>
                    <div className='flex gap-3 mt-4'>
                        <button type='button' onClick={() => closeModal("ltl")} className="basis-1/2 button !text-[#000] !bg-[#f2f2f2] ">Close</button>
                        <button type='submit' className='button basis-1/2'>submit</button>
                    </div>
                </form>
            </dialog>
            <dialog id="invoiceF" className="modal w-[900px] max-w-[900px] mx-auto">
                <form ref={formRef} onSubmit={(e) => logs(e, "invoiceF")} method="dialog" className="modal-box p-10 w-[700px] max-w-[700px] mx-auto">
                    <div className='grid grid-cols-2  gap-3'>
                        <select name='businessType' className="input">
                            <option value="" disabled defaultValue>Company Type</option>
                            <option value="Ltd">Ltd</option>
                            <option value="PLC">PLC</option>
                            <option value="LLP">LLP</option>
                            <option value="Sole Trader">Sole Trader</option>
                            <option value="Partnership">Partnership</option>
                        </select>
                        <input name='businessName' type="text" placeholder="Business Name" className="input" />
                        <input name='contactName' type="text" placeholder="Contact Name" className="input" />
                        <input name='phone' type="text" placeholder="Phone Number" className="input" />
                        <input name='email' type="text" placeholder="Email" className="input" />
                        <input name='businessAddress' type="text" placeholder="Business Address" className="input" />
                        <input name='businessTurnover' type="text" placeholder="Business Turnover Monthly" className="input" />
                        <input name='invoice' type="text" placeholder="Do they Invoice" className="input" />
                    </div>
                    <input name='requiredAmount' type="text" placeholder="Required Amount" className="input w-full mt-3" />
                    <input name='notes' type="text" placeholder="Notes" className="input w-full mt-3" />
                    <p className='mt-4 text-sm font-bold'>Add your Sales Ledger:</p>
                    <div className="ocard">
                        <div className="drag-area" onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop}>
                            {
                                isDragging ? (
                                    <span className="oselect">Drop PDF here</span>
                                ) : (
                                    <>
                                        Drag & Drop PDF here or {""}
                                        <span className="oselect" role='button' onClick={selectFiles}>
                                            Browse
                                        </span>
                                    </>
                                )
                            }


                            <input id='mypdf' type="file" name='file' className='ofile' multiple ref={fileInputRef} onChange={onFileSelect} />
                        </div>
                        <div className="ocontainer">
                            {
                                allpdf.map((item, i) => (
                                    <div className="pdf text-sm" key={i}>
                                        {item.name}
                                        <span onClick={() => removeFromUpload(i)} className='delete'>&times;</span>
                                    </div>
                                ))
                            }

                        </div>
                    </div>
                    <div className='flex gap-3 mt-4'>
                        <button type='button' onClick={() => closeModal("invoiceF")} className="basis-1/2 button !text-[#000] !bg-[#f2f2f2] ">Close</button>
                        <button type='submit' className='button basis-1/2'>submit</button>
                    </div>
                </form>
            </dialog>
            <dialog id="investment" className="modal w-[900px] max-w-[900px] mx-auto">
                <form ref={formRef} onSubmit={(e) => logs(e, "investment")} method="dialog" className="modal-box p-10 w-[700px] max-w-[700px] mx-auto">
                    <div className='grid grid-cols-2  gap-3'>
                        <select name='businessType' className="input">
                            <option value="" disabled defaultValue>Company Type</option>
                            <option value="Ltd">Ltd</option>
                            <option value="PLC">PLC</option>
                            <option value="LLP">LLP</option>
                            <option value="Sole Trader">Sole Trader</option>
                            <option value="Partnership">Partnership</option>
                        </select>
                        <input name='businessName' type="text" placeholder="Business Name" className="input" />
                        <input name='contactName' type="text" placeholder="Contact Name" className="input" />
                        <input name='phone' type="text" placeholder="Phone Number" className="input" />
                        <input name='email' type="text" placeholder="Email" className="input" />
                        <input name='tradingTime' type="text" placeholder="How long have they been trading" className="input" />
                    </div>
                    <input name='requiredAmount' type="text" placeholder="Required Amount" className="input w-full mt-3" />
                    <input name='notes' type="text" placeholder="Notes" className="input w-full mt-3" />
                    <div className='flex gap-3 mt-4'>
                        <button type='button' onClick={() => closeModal("investment")} className="basis-1/2 button !text-[#000] !bg-[#f2f2f2] ">Close</button>
                        <button type='submit' className='button basis-1/2'>submit</button>
                    </div>
                </form>
            </dialog>
        </div>
    )
}

export default CustomerDashboard