import React, { useState } from 'react';

const CompanyInfoPage = () => {
    const [copied, setCopied] = useState(false);

    const copyText = () => {
        const textToCopy = document.getElementById('copyText').innerText;
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                setCopied(true);
                setTimeout(() => {
                    setCopied(false);
                }, 2000); // Reset copied status after 2 seconds
            })
            .catch(error => {
                console.error('Unable to copy text: ', error);
            });
    };
    return (
        <div className="container mx-auto p-8">
            <h1 className="text-3xl font-bold mb-4">Company Info</h1>

            <div className="mockup-window border bg-base-300 relative">
                <button className='btn btn-sm absolute top-5 right-5 text-white bg-primary hover:bg-[#b91200]' onClick={copyText}>{copied ? 'Copied!' : 'Copy'}</button>
                <p id="copyText" className='p-6 pb-9'>
                    Hi [name]
                    <br /><br />
                    Thank you for taking my call today. As discussed can you send me the last 6 months business bank statements through so we can get an offer in place.
                    <br /><br />
                    You can reply to this e-mail with them as attachments or alternatively you can use the secure link below to upload statements where the underwriting team will pick them up.
                    <br /><br />
                    Website: <a className='text-primary' href='https://sedgefunding.co.uk/upload-bank-statement'>https://sedgefunding.co.uk/upload-bank-statement</a> <br /><br />
                    Sedge Funding has a new Business Whattapp number where you can also send pdf statements, the number is 07447997103
                </p>
            </div>

            <div className="my-8">
                <h2 className="text-xl font-semibold mb-2">Upload Statements</h2>
                <a target='_blank' href="https://sedgefunding.co.uk/upload-bank-statement/" className="text-blue-500">https://sedgefunding.co.uk/upload-bank-statement/</a>
            </div>

            <div className="mb-8">
                <h2 className="text-xl font-semibold mb-2">Whattapp</h2>
                <p>Sedge Funding has a new Business Whattapp number for customers to send information such as ID. The number is <span className="font-semibold">+447447997103</span></p>
            </div>

            <div className="mb-8">
                <h2 className="text-xl font-semibold mb-2">Calendar</h2>
                <p>The new calender system is now live on the portal. The main features are as follows -</p>
                <ul className="list-disc ml-8">
                    <li>Request holidays for approval</li>
                    <li>See others holidays so to avoid clashes</li>
                    <li>System will count holidays for the year (future development)</li>
                    <li>Will show important dates such as Bank Holidays (future development)</li>
                </ul>
            </div>

            <div className="mb-8">
                <h2 className="text-xl font-semibold mb-2">Pay / Bonuses</h2>
                <ul className="list-disc ml-8">
                    <li>For Staff on UK PAYE, the pay run is last working day of the month.</li>
                    <li>All other Staff have pre agreed pay run schedules.</li>
                    <li>Bonus structure is based on deals funded within each month.</li>
                    <li>Monthly bonuses are due on the last working day of the month but are sometimes advanced around mid month.</li>
                </ul>
            </div>

            <div className="mb-8">
                <h2 className="text-xl font-semibold mb-2">QA Bonus</h2>
                <p>This is a new section on the portal to monitor performance. This also gives an additional financial bonus ranging from £100 to £1,000 per month for qualifying performance. Ratings will be using the following matrices -</p>
                <ul className="list-disc ml-8">
                    <li>Number of Calls</li>
                    <li>Call Engagement</li>
                    <li>Amount funded</li>
                    <li>Ration of new customers</li>
                    <li>Sick Leave</li>
                </ul>
            </div>

            <div className="mb-8">
                <h2 className="text-xl font-semibold mb-2">Time Off</h2>
                <p>Time off requests require 2 weeks notice</p>
            </div>
        </div>
    );
};

export default CompanyInfoPage;
