import React from 'react'

const ViewDeal = ({ item }) => {

    const renderKeyValuePairs = () => {
        const dealData = [];
        for (const key in item) {
            if (item.hasOwnProperty(key)) {
                dealData.push(
                    <div
                        key={key}
                        className="flex items-center justify-between py-3 px-4  border-neutral-content"
                    >
                        <span className="text-gray-600">{key}</span>
                        <span className="text-gray-600 font-semibold">{item[key]}</span>
                    </div>
                );
            }
        }
        return dealData;
    };
    return (
        <div className="w-full border rounded-lg overflow-hidden">
            <div className="bg-gray-100 text-gray-700 text-lg font-semibold px-6 py-4">
                Deal Details
            </div>
            <div className="divide-y text-sm divide-gray-300">{renderKeyValuePairs()}</div>
        </div>
    )
}

export default ViewDeal