import React, { useRef, useState } from 'react'
import { ref, uploadBytes } from "firebase/storage";
import { storage } from '../../firebase/firebase.config';


const EditLoan = ({ item, closeModal, setRefresh, refresh }) => {
    const loantype = item['type'];
    const [allpdf, setAllpdf] = useState([]);
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef(null);

    const onDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
        e.dataTransfer.dropEffect = "copy";
    }
    const onDragLeave = (e) => {
        e.preventDefault();
        setIsDragging(false);
    }
    const onDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        const files = e.dataTransfer.files;
        setAllpdf([...files])
    }

    function selectFiles() {
        fileInputRef.current.click();
    }

    const removeFromUpload = (i) => {

    }

    function onFileSelect(event) {
        const files = event.target.files;
        setAllpdf([...files])
        if (files.length === 0) return;
        for (let i = 0; i < files.length; i++) {
        }
    }

    const logs = (e, f) => {
        e.preventDefault()
        if (loantype === "MCA") {
            const loanData = {
                "business-type": e?.target?.businessType?.value === "Company Type" ? item["business-type"] : e?.target?.businessType?.value,
                "business-name": e?.target?.businessName?.value,
                "business-contact-name": e?.target?.contactName?.value,
                "business-phone": e?.target?.phone?.value,
                "business-email": e?.target?.email?.value,
                "business-address": e?.target?.businessAddress?.value,
                "business-chip": e?.target?.chipPin?.value,
                "business-turnover": e?.target?.businessTurnover?.value,
                "business-invoice": e?.target?.invoice?.value,
                "business-notes": e?.target?.notes?.value,
                "required-amount": e?.target?.requiredAmount?.value,
                "funding-manager": e?.target?.fundingManager?.value,
                "funded-amount": e?.target?.fundedAmount?.value,
                "status": e?.target?.status?.value === "Status" ? item["status"] : e?.target?.status?.value,
                "type": e?.target?.type?.value,
            };
            fetch(`${process.env.REACT_APP_SERVER_URL}/edit-loan/${item._id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(loanData)
            })
                .then(response => response.json())
                .then(result => {
                    if (allpdf.length !== 0) {
                        const files = allpdf;
                        const fileUploadPromises = [];
                        for (const file of files) {
                            const pdfREF = ref(storage, `pdf/${result?.insertedId}/${file.name}`);
                            const uploadPromise = uploadBytes(pdfREF, file);
                            fileUploadPromises.push(uploadPromise);
                        }

                        setAllpdf([]);
                    }
                    setRefresh(!refresh);
                    closeModal("editLoan");
                    return;
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
        else if (loantype === "Business Loan") {
            const loanData = {
                "business-type": e?.target?.businessType?.value === "Company Type" ? item["business-type"] : e?.target?.businessType?.value,
                "business-name": e?.target?.businessName?.value,
                "business-contact-name": e?.target?.contactName?.value,
                "business-phone": e?.target?.phone?.value,
                "user": e?.target?.user?.value,
                "business-email": e?.target?.email?.value,
                "business-address": e?.target?.businessAddress?.value,
                "business-turnover": e?.target?.businessTurnover?.value,
                "business-invoice": e?.target?.invoice?.value,
                "business-notes": e?.target?.notes?.value,
                "required-amount": e?.target?.requiredAmount?.value,
                "funding-manager": e?.target?.fundingManager?.value,
                "funded-amount": e?.target?.fundedAmount?.value,
                "status": e?.target?.status?.value === "Status" ? item["status"] : e?.target?.status?.value,
                "type": e?.target?.type?.value,
            };
            fetch(`${process.env.REACT_APP_SERVER_URL}/edit-loan/${item._id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(loanData)
            })
                .then(response => response.json())
                .then(result => {
                    if (allpdf.length !== 0) {
                        const files = allpdf;
                        const fileUploadPromises = [];
                        for (const file of files) {
                            const pdfREF = ref(storage, `pdf/${result?.insertedId}/${file.name}`);
                            const uploadPromise = uploadBytes(pdfREF, file);
                            fileUploadPromises.push(uploadPromise);
                        }

                        setAllpdf([]);
                    }
                    setRefresh(!refresh);
                    closeModal("editLoan");
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
        else if (loantype === "Invoice") {
            const loanData = {
                "business-type": e?.target?.businessType?.value === "Company Type" ? item["business-type"] : e?.target?.businessType?.value,
                "business-name": e?.target?.businessName?.value,
                "business-contact-name": e?.target?.contactName?.value,
                "business-phone": e?.target?.phone?.value,
                "business-email": e?.target?.email?.value,
                "business-address": e?.target?.businessAddress?.value,
                "business-turnover": e?.target?.businessTurnover?.value,
                "business-invoice": e?.target?.invoice?.value,
                "business-notes": e?.target?.notes?.value,
                "required-amount": e?.target?.requiredAmount?.value,
                "type": e?.target?.type?.value,
                "funding-manager": e?.target?.fundingManager?.value,
                "funded-amount": e?.target?.fundedAmount?.value,
                "status": e?.target?.status?.value === "Status" ? item["status"] : e?.target?.status?.value,
            };
            fetch(`${process.env.REACT_APP_SERVER_URL}/edit-loan/${item._id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(loanData)
            })
                .then(response => response.json())
                .then(result => {
                    setRefresh(!refresh);
                    closeModal("editLoan");
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
        else if (loantype === "Grants") {

            const loanData = {
                "business-type": e?.target?.businessType?.value === "Company Type" ? item["business-type"] : e?.target?.businessType?.value,
                "business-name": e?.target?.businessName?.value,
                "business-contact-name": e?.target?.contactName?.value,
                "business-phone": e?.target?.phone?.value,
                "business-email": e?.target?.email?.value,
                "trading-duration": e?.target?.tradingTime?.value,
                "business-notes": e?.target?.notes?.value,
                "required-amount": e?.target?.requiredAmount?.value,
                "type": e?.target?.type?.value,
                "funding-manager": e?.target?.fundingManager?.value,
                "funded-amount": e?.target?.fundedAmount?.value,
                "status": e?.target?.status?.value === "Status" ? item["status"] : e?.target?.status?.value,
            };
            fetch(`${process.env.REACT_APP_SERVER_URL}/edit-loan/${item._id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(loanData)
            })
                .then(response => response.json())
                .then(result => {
                    setRefresh(!refresh);
                    closeModal("editLoan");
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
        else if (loantype === "Renewal MCA") {
            const loanData = {
                "business-type": e?.target?.businessType?.value === "Company Type" ? item["business-type"] : e?.target?.businessType?.value,
                "business-name": e?.target?.businessName?.value,
                "business-contact-name": e?.target?.contactName?.value,
                "business-phone": e?.target?.phone?.value,
                "business-email": e?.target?.email?.value,
                "business-address": e?.target?.businessAddress?.value,
                "business-chip": e?.target?.chipPin?.value,
                "business-turnover": e?.target?.businessTurnover?.value,
                "business-invoice": e?.target?.invoice?.value,
                "business-notes": e?.target?.notes?.value,
                "required-amount": e?.target?.requiredAmount?.value,
                "funding-manager": e?.target?.fundingManager?.value,
                "funded-amount": e?.target?.fundedAmount?.value,
                "status": e?.target?.status?.value === "Status" ? item["status"] : e?.target?.status?.value,
                "type": e?.target?.type?.value,
            };
            fetch(`${process.env.REACT_APP_SERVER_URL}/edit-loan/${item._id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(loanData)
            })
                .then(response => response.json())
                .then(result => {
                    if (allpdf.length !== 0) {
                        const files = allpdf;
                        const fileUploadPromises = [];
                        for (const file of files) {
                            const pdfREF = ref(storage, `pdf/${result?.insertedId}/${file.name}`);
                            const uploadPromise = uploadBytes(pdfREF, file);
                            fileUploadPromises.push(uploadPromise);
                        }

                        setAllpdf([]);
                    }
                    setRefresh(!refresh);
                    closeModal("editLoan");
                    return;
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
        else if (loantype === "Renewal Loan") {
            const loanData = {
                "business-type": e?.target?.businessType?.value === "Company Type" ? item["business-type"] : e?.target?.businessType?.value,
                "business-name": e?.target?.businessName?.value,
                "business-contact-name": e?.target?.contactName?.value,
                "business-phone": e?.target?.phone?.value,
                "user": e?.target?.user?.value,
                "business-email": e?.target?.email?.value,
                "business-address": e?.target?.businessAddress?.value,
                "business-turnover": e?.target?.businessTurnover?.value,
                "business-invoice": e?.target?.invoice?.value,
                "business-notes": e?.target?.notes?.value,
                "required-amount": e?.target?.requiredAmount?.value,
                "funding-manager": e?.target?.fundingManager?.value,
                "funded-amount": e?.target?.fundedAmount?.value,
                "status": e?.target?.status?.value === "Status" ? item["status"] : e?.target?.status?.value,
                "type": e?.target?.type?.value,
            };
            fetch(`${process.env.REACT_APP_SERVER_URL}/edit-loan/${item._id}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(loanData)
            })
                .then(response => response.json())
                .then(result => {
                    if (allpdf.length !== 0) {
                        const files = allpdf;
                        const fileUploadPromises = [];
                        for (const file of files) {
                            const pdfREF = ref(storage, `pdf/${result?.insertedId}/${file.name}`);
                            const uploadPromise = uploadBytes(pdfREF, file);
                            fileUploadPromises.push(uploadPromise);
                        }

                        setAllpdf([]);
                    }
                    setRefresh(!refresh);
                    closeModal("editLoan");
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        }
    }

    return (
        <div>
            {
                loantype === "MCA" && <form onSubmit={(e) => logs(e, "stl")} className="rounded-lg">
                    <div className='grid grid-cols-2 gap-3'>
                        <select name='businessType' className="input">
                            <option value="Company Type" disabled selected>Company Type</option>
                            <option value="Ltd">Ltd</option>
                            <option value="PLC">PLC</option>
                            <option value="LLP">LLP</option>
                            <option value="Sole Trader">Sole Trader</option>
                            <option value="Partnership">Partnership</option>
                        </select>
                        <input name='businessName' type="text" placeholder="Business Name" defaultValue={item['business-name']} className="input" />
                        <input name='contactName' type="text" placeholder="Contact Name" defaultValue={item['business-contact-name']} className="input" />
                        <input name='phone' type="text" placeholder="Phone Number" defaultValue={item['business-phone']} className="input" />
                        <input name='email' type="text" placeholder="Email" defaultValue={item['user']} className="input" />
                        <input name='businessAddress' type="text" placeholder="Business Address" defaultValue={item['business-address']} className="input" />
                        <input name='chipPin' type="text" placeholder="Chip & Pin monthly" defaultValue={item['business-chip']} className="input" />
                        <input name='businessTurnover' type="text" placeholder="Business Turnover Monthly" defaultValue={item['business-turnover']} className="input" />
                        <input name='invoice' type="text" placeholder="Do they Invoice" defaultValue={item['business-invoice']} className="input" />
                        <input name='requiredAmount' type="text" placeholder="Required Amount" defaultValue={item['required-amount']} className="input" />
                        <select name='status' className="input">
                            <option value="Status" disabled selected>Status</option>
                            <option value="Initial Review">Initial Review</option>
                            <option value="Additional Documents">Additional Documents</option>
                            <option value="Underwriting">Underwriting</option>
                            <option value="Offer">Offer</option>
                            <option value="Contract Sent">Contract Sent</option>
                            <option value="Funded">Funded</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Start Review">Start Review</option>
                            <option value="Awaiting Start Payment">Awaiting Start Payment</option>
                            <option value="Pitch Creation">Pitch Creation</option>
                            <option value="Online Posted">Online Posted</option>
                            <option value="Sent to Larger Investors">Sent to Larger Investors</option>
                            <option value="Awaiting Payment">Awaiting Payment</option>
                        </select>
                        {/* <input name='fundingManager' defaultValue={item['funding-manager']} type="text" placeholder="Funding Manager" className="input" /> */}
                        <select name='fundingManager' className="input">
                            <option value={item['funding-manager']} disabled selected>{item['funding-manager'] || 'Funding Manager'}</option>
                            <option value="virgil" defaultValue>Virgil</option>
                            <option value="Smit">Smit</option>
                            <option value="Tan">Tan</option>
                            <option value="Team">Team</option>
                            <option value="Support">Support</option>
                        </select>
                        <select name='type' className="input">
                            <option value={item.type} defaultValue disabled>{item.type}</option>
                            <option value="MCA">MCA</option>
                            <option value="Business Loan">Business Loan</option>
                            <option value="Grants">Grants</option>
                            <option value="Invoice">Invoice</option>
                        </select>
                        <input name='fundedAmount' defaultValue={item['funded-amount']} type="text" placeholder="Funded Amount" className="input" />
                    </div>
                    <input name='notes' type="text" placeholder="Notes" defaultValue={item['business-notes']} className="input w-full mt-3" />
                    {/* <div className="ocard">
                        <div className="drag-area" onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop}>
                            {
                                isDragging ? (
                                    <span className="oselect">Drop PDF here</span>
                                ) : (
                                    <>
                                        Drag & Drop PDF here or {""}
                                        <span className="oselect" role='button' onClick={selectFiles}>
                                            Browse
                                        </span>
                                    </>
                                )
                            }


                            <input id='mypdf' type="file" name='file' className='ofile' multiple ref={fileInputRef} onChange={onFileSelect} />
                        </div>
                        <div className="ocontainer">
                            {
                                allpdf.map((item, i) => (
                                    <div className="pdf text-sm" key={i}>
                                        {item.name}
                                        <span onClick={() => removeFromUpload(i)} className='delete'>&times;</span>
                                    </div>
                                ))
                            }

                        </div>
                    </div> */}
                    <div className='flex gap-3 mt-4'>
                        <button type='button' onClick={() => closeModal("editLoan")} className="basis-1/2 button !text-[#000] !bg-[#f2f2f2] !rounded">Close</button>
                        <button type='submit' className='button basis-1/2 !rounded'>submit</button>
                    </div>
                </form>
            }
            {
                loantype === "Business Loan" && <form onSubmit={(e) => logs(e, "ltl")} className="rounded-lg">
                    <div className='grid grid-cols-2 gap-3'>
                        <select name='businessType' className="input">
                            <option value="Company Type" disabled selected>Company Type</option>
                            <option value="Ltd">Ltd</option>
                            <option value="PLC">PLC</option>
                            <option value="LLP">LLP</option>
                            <option value="Sole Trader">Sole Trader</option>
                            <option value="Partnership">Partnership</option>
                        </select>
                        <input name='businessName' type="text" placeholder="Business Name" defaultValue={item['business-name']} className="input" />
                        <input name='contactName' type="text" placeholder="Contact Name" defaultValue={item['business-contact-name']} className="input" />
                        <input name='phone' type="text" placeholder="Phone Number" defaultValue={item['business-phone']} className="input" />
                        <input name='email' type="text" placeholder="Email" defaultValue={item['business-email']} className="input" />
                        <input name='businessAddress' type="text" placeholder="Business Address" defaultValue={item['business-address']} className="input" />
                        <input name='businessTurnover' type="text" placeholder="Business Turnover Monthly" defaultValue={item['business-turnover']} className="input" />
                        <input name='invoice' type="text" placeholder="Do they Invoice" defaultValue={item['business-invoice']} className="input" />
                        <input name='requiredAmount' type="text" placeholder="Required Amount" defaultValue={item['required-amount']} className="input" />
                        <select name='status' className="input">
                            <option value="Status" disabled selected>Status</option>
                            <option value="Initial Review">Initial Review</option>
                            <option value="Additional Documents">Additional Documents</option>
                            <option value="Underwriting">Underwriting</option>
                            <option value="Offer">Offer</option>
                            <option value="Contract Sent">Contract Sent</option>
                            <option value="Funded">Funded</option>
                            <option value="Last Chance">Last Chance</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Cancelled for ever">Cancelled for ever</option>
                            <option value="Start Review">Start Review</option>
                            <option value="Awaiting Start Payment">Awaiting Start Payment</option>
                            <option value="Pitch Creation">Pitch Creation</option>
                            <option value="Online Posted">Online Posted</option>
                            <option value="Sent to Larger Investors">Sent to Larger Investors</option>
                            <option value="Awaiting Payment">Awaiting Payment</option>
                        </select>
                        {/* <input defaultValue={item['funding-manager']} name='fundingManager' type="text" placeholder="Funding Manager" className="input" /> */}
                        <select name='fundingManager' className="input">
                            <option value={item['funding-manager']} disabled selected>{item['funding-manager'] || 'Funding Manager'}</option>
                            <option value="virgil" defaultValue>Virgil</option>
                            <option value="Smit">Smit</option>
                            <option value="Tan">Tan</option>
                            <option value="Team">Team</option>
                            <option value="Support">Support</option>
                        </select>
                        <select name='type' className="input">
                            <option value={item.type} defaultValue>{item.type}</option>
                            <option value="MCA">MCA</option>
                            <option value="Business Loan">Business Loan</option>
                            <option value="Grants">Grants</option>
                            <option value="Invoice">Invoice</option>
                        </select>
                        <input defaultValue={item['user']} name='user' type="text" placeholder="User" className="input" />
                        <input defaultValue={item['funded-amount']} name='fundedAmount' type="text" placeholder="Funded Amount" className="input" />
                    </div>
                    <input name='notes' type="text" placeholder="Notes" defaultValue={item['business-notes']} className="input w-full mt-3" />
                    {/* <div className="ocard">
                        <div className="drag-area" onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop}>
                            {
                                isDragging ? (
                                    <span className="oselect">Drop PDF here</span>
                                ) : (
                                    <>
                                        Drag & Drop PDF here or {""}
                                        <span className="oselect" role='button' onClick={selectFiles}>
                                            Browse
                                        </span>
                                    </>
                                )
                            }


                            <input id='mypdf' type="file" name='file' className='ofile' multiple ref={fileInputRef} onChange={onFileSelect} />
                        </div>
                        <div className="ocontainer">
                            {
                                allpdf.map((item, i) => (
                                    <div className="pdf text-sm" key={i}>
                                        {item.name}
                                        <span onClick={() => removeFromUpload(i)} className='delete'>&times;</span>
                                    </div>
                                ))
                            }

                        </div>
                    </div> */}
                    <div className='flex gap-3 mt-4'>
                        <button type='button' onClick={() => closeModal("editLoan")} className="basis-1/2 button !text-[#000] !bg-[#f2f2f2] !rounded">Close</button>
                        <button type='submit' className='button basis-1/2 !rounded'>submit</button>
                    </div>
                </form>
            }
            {
                loantype === "Invoice" && <form onSubmit={(e) => logs(e, "invoiceF")} className="">
                    <div className='grid grid-cols-2  gap-3'>
                        <select name='businessType' className="input">
                            <option value="Company Type" defaultValue>Company Type</option>
                            <option value="Ltd">Ltd</option>
                            <option value="PLC">PLC</option>
                            <option value="LLP">LLP</option>
                            <option value="Sole Trader">Sole Trader</option>
                            <option value="Partnership">Partnership</option>
                        </select>
                        <input name='businessName' type="text" placeholder="Business Name" defaultValue={item['business-name']} className="input" />
                        <input name='contactName' type="text" placeholder="Contact Name" defaultValue={item['business-contact-name']} className="input" />
                        <input name='phone' type="text" defaultValue={item['business-phone']} placeholder="Phone Number" className="input" />
                        <input name='email' defaultValue={item['user']} type="text" placeholder="Email" className="input" />
                        <input name='businessAddress' type="text" placeholder="Business Address" defaultValue={item['business-address']} className="input" />
                        <input name='businessTurnover' type="text" defaultValue={item['business-turnover']} placeholder="Business Turnover Monthly" className="input" />
                        <input name='invoice' type="text" placeholder="Do they Invoice" defaultValue={item['business-invoice']} className="input" />
                        <select name='status' className="input">
                            <option value="Status" disabled selected>Status</option>
                            <option value="Initial Review">Initial Review</option>
                            <option value="Additional Documents">Additional Documents</option>
                            <option value="Underwriting">Underwriting</option>
                            <option value="Offer">Offer</option>
                            <option value="Contract Sent">Contract Sent</option>
                            <option value="Funded">Funded</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Start Review">Start Review</option>
                            <option value="Awaiting Start Payment">Awaiting Start Payment</option>
                            <option value="Pitch Creation">Pitch Creation</option>
                            <option value="Online Posted">Online Posted</option>
                            <option value="Sent to Larger Investors">Sent to Larger Investors</option>
                            <option value="Awaiting Payment">Awaiting Payment</option>
                        </select>
                        {/* <input defaultValue={item['funding-manager']} name='fundingManager' type="text" placeholder="Funding Manager" className="input" /> */}
                        <select name='fundingManager' className="input">
                            <option value={item['funding-manager']} disabled selected>{item['funding-manager'] || 'Funding Manager'}</option>
                            <option value="virgil" defaultValue>Virgil</option>
                            <option value="Smit">Smit</option>
                            <option value="Tan">Tan</option>
                            <option value="Team">Team</option>
                            <option value="Support">Support</option>
                        </select>

                        <input defaultValue={item['funded-amount']} name='fundedAmount' type="text" placeholder="Funded Amount" className="input " />
                        <select name='type' className="input">
                            <option value={item.type} defaultValue >{item.type}</option>
                            <option value="MCA">MCA</option>
                            <option value="Business Loan">Business Loan</option>
                            <option value="Grants">Grants</option>
                            <option value="Invoice">Invoice</option>
                        </select>
                    </div>
                    <input name='requiredAmount' type="text" placeholder="Required Amount" defaultValue={item['required-amount']} className="input mt-3 w-full" />

                    <input name='notes' type="text" placeholder="Notes" defaultValue={item['business-notes']} className="input w-full mt-3" />
                    {/* <div className="ocard">
                        <div className="drag-area" onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop}>
                            {
                                isDragging ? (
                                    <span className="oselect">Drop PDF here</span>
                                ) : (
                                    <>
                                        Drag & Drop PDF here or {""}
                                        <span className="oselect" role='button' onClick={selectFiles}>
                                            Browse
                                        </span>
                                    </>
                                )
                            }


                            <input id='mypdf' type="file" name='file' className='ofile' multiple ref={fileInputRef} onChange={onFileSelect} />
                        </div>
                        <div className="ocontainer">
                            {
                                allpdf.map((item, i) => (
                                    <div className="pdf text-sm" key={i}>
                                        {item.name}
                                        <span onClick={() => removeFromUpload(i)} className='delete'>&times;</span>
                                    </div>
                                ))
                            }

                        </div>
                    </div> */}
                    <div className='flex gap-3 mt-4'>
                        {/* <button onClick={() => closeModal("")} type='button' className='button basis-1/2'>cancel</button> */}
                        <button type='button' onClick={() => closeModal("editLoan")} className="basis-1/2 button !text-[#000] !bg-[#f2f2f2] ">Close</button>
                        <button type='submit' className='button basis-1/2'>submit</button>
                    </div>
                </form>
            }
            {
                loantype === "Grants" && <form onSubmit={(e) => logs(e, "invoiceF")} method="dialog">
                    <div className='grid grid-cols-2  gap-3'>
                        <select name='businessType' className="input">
                            <option value="Company Type" defaultValue>Company Type</option>
                            <option value="Ltd">Ltd</option>
                            <option value="PLC">PLC</option>
                            <option value="LLP">LLP</option>
                            <option value="Sole Trader">Sole Trader</option>
                            <option value="Partnership">Partnership</option>
                        </select>
                        <input name='businessName' defaultValue={item['business-name']} type="text" placeholder="Business Name" className="input" />
                        <input name='contactName' defaultValue={item['business-contact-name']} type="text" placeholder="Contact Name" className="input" />
                        <input name='phone' type="text" defaultValue={item['business-phone']} placeholder="Phone Number" className="input" />
                        <input name='email' type="text" placeholder="Email" defaultValue={item['user']} className="input" />
                        <input name='tradingTime' type="text" placeholder="How long have they been trading" defaultValue={item['trading-duration']} className="input" />
                        <select name='status' className="input">
                            <option value="Status" disabled selected>Status</option>
                            <option value="Initial Review">Initial Review</option>
                            <option value="Additional Documents">Additional Documents</option>
                            <option value="Underwriting">Underwriting</option>
                            <option value="Offer">Offer</option>
                            <option value="Contract Sent">Contract Sent</option>
                            <option value="Funded">Funded</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Start Review">Start Review</option>
                            <option value="Awaiting Start Payment">Awaiting Start Payment</option>
                            <option value="Pitch Creation">Pitch Creation</option>
                            <option value="Online Posted">Online Posted</option>
                            <option value="Sent to Larger Investors">Sent to Larger Investors</option>
                            <option value="Awaiting Payment">Awaiting Payment</option>
                        </select>
                        {/* <input defaultValue={item['funding-manager']} name='fundingManager' type="text" placeholder="Funding Manager" className="input" /> */}
                        <select name='fundingManager' className="input">
                            <option value={item['funding-manager']} disabled selected>{item['funding-manager'] || 'Funding Manager'}</option>
                            <option value="virgil" defaultValue>Virgil</option>
                            <option value="Smit">Smit</option>
                            <option value="Tan">Tan</option>
                            <option value="Team">Team</option>
                            <option value="Support">Support</option>
                        </select>
                        <select name='type' className="input">
                            <option value={item.type} defaultValue>{item.type}</option>
                            <option value="MCA">MCA</option>
                            <option value="Business Loan">Business Loan</option>
                            <option value="Grants">Grants</option>
                            <option value="Invoice">Invoice</option>
                        </select>
                        <input defaultValue={item['funded-amount']} name='fundedAmount' type="text" placeholder="Funded Amount" className="input" />
                    </div>
                    <input name='requiredAmount' type="text" placeholder="Required Amount" className="input w-full mt-3" defaultValue={item['required-amount']} />
                    <input name='notes' type="text" placeholder="Notes" className="input w-full mt-3" />
                    <div className='flex gap-3 mt-4'>
                        {/* <button onClick={() => closeModal("")} type='button' className='button basis-1/2'>cancel</button> */}
                        <button type='button' onClick={() => closeModal("editLoan")} className="basis-1/2 button !text-[#000] !bg-[#f2f2f2] ">Close</button>
                        <button type='submit' className='button basis-1/2'>submit</button>
                    </div>
                </form>
            }
            {
                loantype === "Renewal MCA" && <form onSubmit={(e) => logs(e, "stl")} className="rounded-lg">
                    <div className='grid grid-cols-2 gap-3'>
                        <select name='businessType' className="input">
                            <option value="Company Type" disabled selected>Company Type</option>
                            <option value="Ltd">Ltd</option>
                            <option value="PLC">PLC</option>
                            <option value="LLP">LLP</option>
                            <option value="Sole Trader">Sole Trader</option>
                            <option value="Partnership">Partnership</option>
                        </select>
                        <input name='businessName' type="text" placeholder="Business Name" defaultValue={item['business-name']} className="input" />
                        <input name='contactName' type="text" placeholder="Contact Name" defaultValue={item['business-contact-name']} className="input" />
                        <input name='phone' type="text" placeholder="Phone Number" defaultValue={item['business-phone']} className="input" />
                        <input name='email' type="text" placeholder="Email" defaultValue={item['user']} className="input" />
                        <input name='businessAddress' type="text" placeholder="Business Address" defaultValue={item['business-address']} className="input" />
                        <input name='chipPin' type="text" placeholder="Chip & Pin monthly" defaultValue={item['business-chip']} className="input" />
                        <input name='businessTurnover' type="text" placeholder="Business Turnover Monthly" defaultValue={item['business-turnover']} className="input" />
                        <input name='invoice' type="text" placeholder="Do they Invoice" defaultValue={item['business-invoice']} className="input" />
                        <input name='requiredAmount' type="text" placeholder="Required Amount" defaultValue={item['required-amount']} className="input" />
                        <select name='status' className="input">
                            <option value="Status" disabled selected>Status</option>
                            <option value="Initial Review">Initial Review</option>
                            <option value="Additional Documents">Additional Documents</option>
                            <option value="Underwriting">Underwriting</option>
                            <option value="Offer">Offer</option>
                            <option value="Contract Sent">Contract Sent</option>
                            <option value="Funded">Funded</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Start Review">Start Review</option>
                            <option value="Awaiting Start Payment">Awaiting Start Payment</option>
                            <option value="Pitch Creation">Pitch Creation</option>
                            <option value="Online Posted">Online Posted</option>
                            <option value="Sent to Larger Investors">Sent to Larger Investors</option>
                            <option value="Awaiting Payment">Awaiting Payment</option>
                        </select>
                        {/* <input name='fundingManager' defaultValue={item['funding-manager']} type="text" placeholder="Funding Manager" className="input" /> */}
                        <select name='fundingManager' className="input">
                            <option value={item['funding-manager']} disabled selected>{item['funding-manager'] || 'Funding Manager'}</option>
                            <option value="virgil" defaultValue>Virgil</option>
                            <option value="Smit">Smit</option>
                            <option value="Tan">Tan</option>
                            <option value="Team">Team</option>
                            <option value="Support">Support</option>
                        </select>
                        <select name='type' className="input">
                            <option value={item.type} defaultValue disabled>{item.type}</option>
                            <option value="MCA">MCA</option>
                            <option value="Business Loan">Business Loan</option>
                            <option value="Grants">Grants</option>
                            <option value="Invoice">Invoice</option>
                        </select>
                        <input name='fundedAmount' defaultValue={item['funded-amount']} type="text" placeholder="Funded Amount" className="input" />
                    </div>
                    <input name='notes' type="text" placeholder="Notes" defaultValue={item['business-notes']} className="input w-full mt-3" />
                    {/* <div className="ocard">
                        <div className="drag-area" onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop}>
                            {
                                isDragging ? (
                                    <span className="oselect">Drop PDF here</span>
                                ) : (
                                    <>
                                        Drag & Drop PDF here or {""}
                                        <span className="oselect" role='button' onClick={selectFiles}>
                                            Browse
                                        </span>
                                    </>
                                )
                            }


                            <input id='mypdf' type="file" name='file' className='ofile' multiple ref={fileInputRef} onChange={onFileSelect} />
                        </div>
                        <div className="ocontainer">
                            {
                                allpdf.map((item, i) => (
                                    <div className="pdf text-sm" key={i}>
                                        {item.name}
                                        <span onClick={() => removeFromUpload(i)} className='delete'>&times;</span>
                                    </div>
                                ))
                            }

                        </div>
                    </div> */}
                    <div className='flex gap-3 mt-4'>
                        <button type='button' onClick={() => closeModal("editLoan")} className="basis-1/2 button !text-[#000] !bg-[#f2f2f2] !rounded">Close</button>
                        <button type='submit' className='button basis-1/2 !rounded'>submit</button>
                    </div>
                </form>
            }
            {
                loantype === "Renewal Loan" && <form onSubmit={(e) => logs(e, "ltl")} className="rounded-lg">
                    <div className='grid grid-cols-2 gap-3'>
                        <select name='businessType' className="input">
                            <option value="Company Type" disabled selected>Company Type</option>
                            <option value="Ltd">Ltd</option>
                            <option value="PLC">PLC</option>
                            <option value="LLP">LLP</option>
                            <option value="Sole Trader">Sole Trader</option>
                            <option value="Partnership">Partnership</option>
                        </select>
                        <input name='businessName' type="text" placeholder="Business Name" defaultValue={item['business-name']} className="input" />
                        <input name='contactName' type="text" placeholder="Contact Name" defaultValue={item['business-contact-name']} className="input" />
                        <input name='phone' type="text" placeholder="Phone Number" defaultValue={item['business-phone']} className="input" />
                        <input name='email' type="text" placeholder="Email" defaultValue={item['business-email']} className="input" />
                        <input name='businessAddress' type="text" placeholder="Business Address" defaultValue={item['business-address']} className="input" />
                        <input name='businessTurnover' type="text" placeholder="Business Turnover Monthly" defaultValue={item['business-turnover']} className="input" />
                        <input name='invoice' type="text" placeholder="Do they Invoice" defaultValue={item['business-invoice']} className="input" />
                        <input name='requiredAmount' type="text" placeholder="Required Amount" defaultValue={item['required-amount']} className="input" />
                        <select name='status' className="input">
                            <option value="Status" disabled selected>Status</option>
                            <option value="Initial Review">Initial Review</option>
                            <option value="Additional Documents">Additional Documents</option>
                            <option value="Underwriting">Underwriting</option>
                            <option value="Offer">Offer</option>
                            <option value="Contract Sent">Contract Sent</option>
                            <option value="Funded">Funded</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Start Review">Start Review</option>
                            <option value="Awaiting Start Payment">Awaiting Start Payment</option>
                            <option value="Pitch Creation">Pitch Creation</option>
                            <option value="Online Posted">Online Posted</option>
                            <option value="Sent to Larger Investors">Sent to Larger Investors</option>
                            <option value="Awaiting Payment">Awaiting Payment</option>
                        </select>
                        {/* <input defaultValue={item['funding-manager']} name='fundingManager' type="text" placeholder="Funding Manager" className="input" /> */}
                        <select name='fundingManager' className="input">
                            <option value={item['funding-manager']} disabled selected>{item['funding-manager'] || 'Funding Manager'}</option>
                            <option value="virgil" defaultValue>Virgil</option>
                            <option value="Smit">Smit</option>
                            <option value="Tan">Tan</option>
                            <option value="Team">Team</option>
                            <option value="Support">Support</option>
                        </select>
                        <select name='type' className="input">
                            <option value={item.type} defaultValue>{item.type}</option>
                            <option value="MCA">MCA</option>
                            <option value="Business Loan">Business Loan</option>
                            <option value="Grants">Grants</option>
                            <option value="Invoice">Invoice</option>
                        </select>
                        <input defaultValue={item['user']} name='user' type="text" placeholder="User" className="input" />
                        <input defaultValue={item['funded-amount']} name='fundedAmount' type="text" placeholder="Funded Amount" className="input" />
                    </div>
                    <input name='notes' type="text" placeholder="Notes" defaultValue={item['business-notes']} className="input w-full mt-3" />
                    {/* <div className="ocard">
                        <div className="drag-area" onDragOver={onDragOver} onDragLeave={onDragLeave} onDrop={onDrop}>
                            {
                                isDragging ? (
                                    <span className="oselect">Drop PDF here</span>
                                ) : (
                                    <>
                                        Drag & Drop PDF here or {""}
                                        <span className="oselect" role='button' onClick={selectFiles}>
                                            Browse
                                        </span>
                                    </>
                                )
                            }


                            <input id='mypdf' type="file" name='file' className='ofile' multiple ref={fileInputRef} onChange={onFileSelect} />
                        </div>
                        <div className="ocontainer">
                            {
                                allpdf.map((item, i) => (
                                    <div className="pdf text-sm" key={i}>
                                        {item.name}
                                        <span onClick={() => removeFromUpload(i)} className='delete'>&times;</span>
                                    </div>
                                ))
                            }

                        </div>
                    </div> */}
                    <div className='flex gap-3 mt-4'>
                        <button type='button' onClick={() => closeModal("editLoan")} className="basis-1/2 button !text-[#000] !bg-[#f2f2f2] !rounded">Close</button>
                        <button type='submit' className='button basis-1/2 !rounded'>submit</button>
                    </div>
                </form>
            }


        </div>
    )
}

export default EditLoan