import React from 'react'
import { Outlet } from 'react-router-dom'
import CustomerSidenav from '../components/CustomerSidenav/CustomerSidenav'
import CustomerNavbar from '../components/CustomerNavbar/CustomerNavbar'

const Customer = () => {
    
    return (
        <div className='flex gap-7'>
            <CustomerSidenav></CustomerSidenav>
            <div className='w-full'>
                <CustomerNavbar></CustomerNavbar>
                <Outlet></Outlet>
            </div>
        </div>
    )
}

export default Customer