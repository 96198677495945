// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getDatabase, ref, set } from 'firebase/database';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAGQpZBvwzGm8U637abLRaRcG3vbv5Ko74",
  authDomain: "readysteady-finance.firebaseapp.com",
  projectId: "readysteady-finance",
  storageBucket: "readysteady-finance.appspot.com",
  messagingSenderId: "74458048265",
  appId: "1:74458048265:web:afe7da1d51cc42c010d5d8",
  databaseURL: "https://readysteady-finance-default-rtdb.europe-west1.firebasedatabase.app"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const db = getFirestore(app);
export const firebaseRrealTmeDataBase = getDatabase(app);
