import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext';

const CustomerLogin = () => {
    const navigate = useNavigate();
    const [error, setError] = useState(false);
    const { signIn, user, setLoading, logOut, loading } = useContext(AuthContext);

    navigate('/admin/login');

    const token = localStorage.getItem('token');
    if (token && user && !loading) {
        fetch(`${process.env.REACT_APP_SERVER_URL}/token-varification`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${token}`
            },
            body: JSON.stringify({ email: user?.email })
        })
            .then(response => response.json())
            .then(data => {
                if (data === false) {
                    return logOut();
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    useEffect(() => {
        if (user) {
            navigate("/customer/dashboard")
        }
    }, [user])


    const loginHandler = (event) => {
        event.preventDefault();
        const email = event.target.email.value;
        const password = event.target.password.value;

        signIn(email, password)
            .then(userCredential => {
                // console.log(userCredential)
                // get jwt token
                setLoading(true);
                fetch(`${process.env.REACT_APP_SERVER_URL}/jwt`, {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ email: userCredential.user.email })
                })
                    .then(res => res.json())
                    .then(data => {
                        setError(false);
                        localStorage.setItem('token', data.token);
                        setLoading(false);
                        navigate("/customer/dashboard");
                    })
            })
            .catch(error => {
                console.log(error);
                setError(true);

            })
        event.target.reset();
    }

    return (
        <div>
            <div className='flex h-screen justify-center overflow-x-hidden'>
                <div className='basis-full px-10 md:px-0 mx-auto md:basis-1/4 flex h-screen items-center '>
                    <div className='basis-full'>
                        <div className='w-[100%] 2xl:w-[80%]'>
                            <div className='mb-[30px]'>
                                <div>
                                    <h3 className='gradient-text-rsf'>Welcome back</h3>
                                </div>
                                <p className='custom-subtitle-rsf'>Enter your email and password to sign in</p>
                            </div>
                            {
                                error ? <div className="alert alert-error bg-[#e63a27] text-white rounded-xl text-sm mb-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                                    <span>Your username or password is incorrect. Please try again.</span>
                                </div> : <></>
                            }
                            <div className='mt-6'>
                                <form onSubmit={loginHandler}>
                                    <div>
                                        <label className='custom-label-rsf mb-3 block'>Email</label>
                                        <div className='custom-input-div-rsf'>
                                            <input className='custom-input-rsf' type="email" name='email' required />
                                        </div>
                                    </div>
                                    <div className='mt-8'>
                                        <label className='custom-label-rsf mb-3 block'>Password</label>
                                        <div className='custom-input-div-rsf'>
                                            <input className='custom-input-rsf' type="password" name='password' required />
                                            <p className='text-right text-xs text-[#9f9f9f] font-semibold mt-2'><Link to={'/forget-password'}>Forget Password?</Link></p>
                                        </div>
                                    </div>


                                    <div>
                                        {/* <button className='custom-rsf-btn mt-[32px] bg-1'>SIGN IN</button> */}
                                        <button type='submit' className='button mt-[32px] w-full'>SIGN IN</button>
                                        {/* <button type='submit' className='custom-rsf-btn mt-[32px] w-full bg-2'>SIGN IN</button> */}
                                        {/* <button className='custom-rsf-btn mt-[32px] bg-3'>SIGN IN</button> */}
                                    </div>
                                    {/* <div className='text-sm mt-4 text-center text-[#67748E]'>
                                        Login as an Admin? <Link to="/admin/login" className='color-rsf font-semibold'> Click Here</Link>
                                    </div> */}

                                    <div className='font-bold text-sm text-[#5a606c] mt-8'>
                                        New to Ready Steady Finance? <br />
                                        Register for Free<Link to="/register" className='color-rsf'> here</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='custom-login-page-img-box basis-[42%] hidden md:block'>
                    <div className='custom-login-page-img-inner-box'>
                        <div className='custom-login-page-img'></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomerLogin