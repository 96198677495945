import React, { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext';

const EditBook = ({ item, closeModal, setRefresh, refresh, dealList, setDealList }) => {

    const { user, loading, country, setCountry } = useContext(AuthContext);
    const submitDeals = (e) => {
        e.preventDefault()
        const loanData = {
            "funder": e?.target?.funder?.value,
            "companyname": e?.target?.companyname?.value,
            "loanamount": e?.target?.loanamount?.value,
            "repayment": e?.target?.repayment?.value,
            "factor": e?.target?.factor?.value,
            "comrate": e?.target?.comrate?.value,
            "comamo": e?.target?.comamo?.value,
            "status": e?.target?.status?.value,
            "closer": e?.target?.closer?.value,
            "note": e?.target?.note?.value,
            "user": user.email,
            "createdDate": e?.target?.date?.value
        };
        fetch(`${process.env.REACT_APP_SERVER_URL}/edit-book/${item._id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(loanData)
        })
            .then(response => response.json())
            .then(result => {
                e.target.reset();
                setRefresh(!refresh);
                closeModal("editBook");
                return;
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    return (
        <form onSubmit={(e) => submitDeals(e)} method="dialog" className="modal-box p-10 w-[700px] max-w-[700px] rounded-lg">
            <div>
                <p className='font-bold mb-3 text-right'>Date: {new Date(item?.createdDate).toLocaleDateString('en-UK', {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric'
                })}</p>
            </div>
            <div className='grid grid-cols-2 gap-3'>
                <select name='funder' className="input" required>
                    <option value={item?.funder} defaultValue>{item?.funder}</option>
                    <option value="Liberis">Liberis</option>
                    <option value="Youlend">Youlend</option>
                    <option value="365 Finance">365 Finance</option>
                    <option value="Sedge">Sedge</option>
                    <option value="Capify">Capify</option>
                    <option value="Playter">Playter</option>
                    <option value="Penny">Penny</option>
                    <option value="IWOCA">IWOCA</option>
                    <option value="Swoop">Swoop</option>
                    <option value="Funding Alt">Funding Alt</option>
                    <option value="Senica">Senica</option>
                    <option value="Maxcap">Maxcap</option>
                    <option value="Swiftfund">Swiftfund</option>
                    <option value="Funding Circle">Funding Circle</option>
                </select>
                <input name='companyname' type="text" placeholder="Company Name" defaultValue={item?.companyname} className="input" />
                <input name='loanamount' type="text" placeholder="Loan Amount" defaultValue={item?.loanamount} className="input" />
                <input name='repayment' type="text" placeholder="Repayment" defaultValue={item?.repayment} className="input" />
                <input name='factor' type="text" placeholder="Factor" defaultValue={item?.factor} className="input" />
                <input name='comrate' type="text" placeholder="Com Rate" defaultValue={item?.comrate} className="input" />
                <input name='comamo' type="text" placeholder="Com Amo" defaultValue={item?.comamo} className="input" />
                {/* <input name='status' type="text" placeholder="Status" defaultValue={item?.status} className="input" /> */}
                <select name='status' className="input mr-3 w-full">
                    <option value={item?.status} selected>Current - {item?.status}</option>
                    <option value="TBC">TBC</option>
                    <option value="Funded">Funded</option>
                    <option value="Renewed">Renewed</option>
                    <option value="Dead">Dead</option>
                    <option value="Moved">Moved</option>
                    <option value="Ready">Ready</option>
                </select>
                <select name='closer' className="input" required>
                    <option value={item?.closer} defaultValue>{item?.closer}</option>
                    <option value="Wayne">Wayne</option>
                    <option value="Jon">Jon</option>
                    <option value="Millie">Millie</option>
                    <option value="Millie">Millie</option>
                    <option value="Smit">Smit</option>
                    <option value="Virgil">Virgil</option>
                    <option value="Dapo">Dapo</option>
                    <option value="Don">Don</option>
                    <option value="Myca">Myca</option>
                    <option value="Mark">Mark</option>
                    <option value="Reggie">Reggie</option>
                    <option value="Tracy">Tracy</option>
                    <option value="Support">Support</option>
                    <option value="New Business">New Business</option>
                    <option value="Jay">Jay</option>
                    <option value="Lou">Lou</option>
                    <option value="Juniel">Juniel</option>
                    <option value="Paul">Paul</option>
                    <option value="Josh">Josh</option>
                    <option value="Aidan">Aidan</option>
                </select>
                <input name='date' type="date" placeholder="Date" defaultValue={item?.createdDate} className="input w-full" required />
            </div>
            <input name='note' type="text" placeholder="Note" defaultValue={item?.note} className="input mt-3 w-full" />
            <div className='flex gap-3 mt-4'>
                {/* <button onClick={() => closeModal("stl")} type='button' className='button basis-1/2'>cancel</button> */}
                <button type='button' onClick={() => closeModal("editBook")} className="basis-1/2 button !text-[#000] !bg-[#f2f2f2] !rounded">Close</button>
                <button type='submit' className='button basis-1/2 !rounded'>submit</button>
            </div>
        </form>
    )
}

export default EditBook