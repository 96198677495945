import React, { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

const SignAgreement = () => {
    const sigCanvas = useRef({});
    const location = useLocation();
    const { id } = useParams();
    const navigate = useNavigate()
    // const history = useHistory();
    const [searchParams] = useSearchParams();

    const details = {
        clientName: searchParams.get('clientName'),
        businessName: searchParams.get('businessName'),
        businessAddress: searchParams.get('businessAddress'),
    }

    const saveSignature = async () => {
        const signature = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
        const blob = await (await fetch(signature)).blob();
        const file = new File([blob], "signature.png", { type: "image/png" });

        const formData = new FormData();
        formData.append('signature', file);
        formData.append('id', id);

        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/upload-signature`, {
            method: 'POST',
            body: formData
        });

        if (response.ok) {
            const data = await response.json();
            navigate('/thank-you')
            console.log('Signature uploaded successfully:', data);
        } else {
            console.error('Failed to upload signature');
        }
    };



    const clearSignature = () => {
        sigCanvas.current.clear();
    };

    return (
        <div className='text-black max-w-[900px] mx-auto py-28'>
            <h1 className='font-bold mb-5'>Business Grants Consultancy Agreement</h1>
            <p className='text-sm'>This Business Grants Consultancy Agreement ("Agreement") is made and entered into as of this ("Effective Date"), by and between:</p>
            <p className='text-sm font-bold mt-3'>Consultant:</p>
            <p className='text-sm'>Sedge Funding Ltd</p>
            <p className='text-sm'>124-128, City Road, London, England, EC1V 2NJ</p>
            <p className='text-sm font-bold mt-3'>Client:</p>
            <p className='text-sm'>{details.businessName}</p>
            <p className='text-sm'>{details.businessAddress}</p>
            <div className='text-sm mt-3'>
                <p className='font-bold'>Recitals</p>
                WHEREAS, the Consultant is in the business of providing consultancy services to assist businesses in obtaining grants;
                WHEREAS, the Client desires to engage the services of the Consultant to assist in identifying and applying for business grants on behalf of the Client;
                NOW, THEREFORE, in consideration of the mutual promises, covenants, and conditions contained herein, the parties hereto agree as follows:<br /><br />
                1. Services Provided The Consultant agrees to provide the following services ("Services"):<br />
                1.1. Identifying suitable grant opportunities for the Client.<br />
                1.2. Assisting in the preparation and submission of grant applications.<br />
                1.3. Providing advisory support throughout the grant application process.<br />
                1.4. Other related services as agreed upon by both parties in writing.<br /><br />
                2. Fees and Payment<br />
                2.1. The Client agrees to pay the Consultant a fee equal to fifteen percent (15%) of the total grant amount awarded to the Client ("Consultancy Fee").<br />
                2.2. The Consultancy Fee is due and payable within seven (7) days of the Client receiving the grant funds.<br />
                2.3. In the event that the Client does not receive a grant, no Consultancy Fee shall be payable.<br />
                2.4. The Client agrees to reimburse the Consultant for any pre-approved out-of-pocket expenses incurred in the course of providing the Services, provided that such expenses are reasonable and necessary.<br />
                2.5. Services are subject to VAT charged at the current rate given by UK government at the time of payments billable.<br /><br />
                3. Term and Termination<br />
                3.1. This Agreement shall commence on the Effective Date and continue until the completion of the Services, unless earlier terminated in accordance with this Agreement.<br />
                3.2. Either party may terminate this Agreement for any reason upon thirty (30) days written notice to the other party.<br />
                3.3. In the event of termination, the Client shall pay the Consultant for all Services rendered and expenses incurred up to the date of termination.<br /><br />
                4. Confidentiality<br />
                4.1. The Consultant agrees to keep confidential all information disclosed by the Client in connection with the Services and shall not use or disclose such information except as necessary to perform the Services or as required by law.<br />
                4.2. The obligation of confidentiality shall survive the termination of this Agreement.<br /><br />
                5. Indemnification<br />
                The Client agrees to indemnify and hold harmless the Consultant from any and all claims, liabilities, damages, and expenses (including reasonable attorney's fees) arising out of or in connection with the Services provided under this Agreement.<br /><br />
                6. Governing Law<br />
                This Agreement shall be governed by and construed in accordance with the law of England and Wales whose courts shall have exclusive jurisdiction, without regard to its conflict of law principles.<br />
                7. Entire Agreement<br />
                This Agreement constitutes the entire agreement between the parties and supersedes all prior and contemporaneous agreements, understandings, negotiations, and discussions, whether oral or written, of the parties. No amendment or modification of this Agreement shall be binding unless executed in writing by both parties.<br /><br />
                8. Severability<br />
                If any provision of this Agreement is held to be invalid or unenforceable, the remaining provisions shall continue in full force and effect.<br /><br />
                9. Waiver<br />
                No waiver of any term or condition of this Agreement shall be construed as a waiver of any other term or condition, nor shall any waiver of any breach of this Agreement be construed as a waiver of any subsequent breach.<br /><br />
                10. Notices<br />
                All notices, requests, demands, and other communications required or permitted under this Agreement shall be in writing and shall be deemed to have been duly given when delivered by hand, sent by certified mail, return receipt requested, or by recognized overnight courier service, to the addresses provided above or to such other address as either party may designate by notice in accordance with this provision.
            </div>
            <p className='text-sm mt-5'>By signing I confirm that I have read and understood the contents of the agreement:</p>

            <p className='mt-4'>{details?.clientName}</p>
            <p>Signature:</p>
            <SignatureCanvas ref={sigCanvas} canvasProps={{ width: 500, height: 200, className: 'sigCanvas border border-black mt-1' }} />
            <button className='btn btn-dark btn-sm mt-6 text-white' onClick={saveSignature}>Save</button>
            <button className='btn btn-black ml-3 btn-sm mt-6 text-white' onClick={clearSignature}>Clear</button>
        </div>
    );
}

export default SignAgreement