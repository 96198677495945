import { createContext, useState } from "react";

export const DataContext = createContext({})

const DataProvider = ({ children }) => {
    const [searchRequestValue, setSearchRequestValue] = useState({});
    const [fundedThisMonth, setFundedThisMonth] = useState('0');
    const [staffComs, setStaffComs] = useState('0');
    const [uploading, setUploading] = useState(false);

    const contextData = {
        setFundedThisMonth,
        fundedThisMonth,
        setStaffComs,
        staffComs,
        searchRequestValue,
        setSearchRequestValue,
        setUploading,
        uploading
    }

    return (
        <DataContext.Provider value={contextData}>
            {children}
        </DataContext.Provider>
    )
}

export default DataProvider;
