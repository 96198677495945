export const serverURL = `${process.env.REACT_APP_SERVER_URL}`;

export const handleGoBack = (navigate, adminData) => {
    // Extract the base path from the current path
    if (adminData && adminData?.role === "super-admin") {
        navigate('/admin/kanaban');
    } else if (adminData && adminData?.role === "admin") {
        navigate('/admin/kanaban');
    } else if (adminData && adminData?.role === "corporate-lending-head") {
        navigate('/shaun/kanban');
    } else if (adminData && adminData?.role === "team") {
        navigate('/team/kanban');
    } else if (adminData && adminData?.role === "mca-loan-manager") {
        navigate('/mcaloan/kanban');
    } else if (adminData && adminData?.role === "new-business-manager") {
        navigate('/newbusiness/kanban');
    }
    else {
        console.log(adminData);
    }

    // Navigate to the base path

};

export const handleAdminRoute = (adminData) => {
    // Extract the base path from the current path
    if (adminData && adminData?.role === "super-admin") {
        return ('/admin/kanaban');
    } else if (adminData && adminData?.role === "admin") {
        return ('/admin/kanaban');
    } else if (adminData && adminData?.role === "corporate-lending-head") {
        return ('/shaun/kanban');
    } else if (adminData && adminData?.role === "team") {
        return ('/team/kanban');
    } else if (adminData && adminData?.role === "mca-loan-manager") {
        return ('/mcaloan/kanban');
    } else if (adminData && adminData?.role === "new-business-manager") {
        return ('/newbusiness/kanban');
    }
    else {
        console.log(adminData);
    }

    // Navigate to the base path

};