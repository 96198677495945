import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { PacmanLoader } from 'react-spinners';
import { AuthContext } from '../../context/AuthContext';

const QaBonus = () => {
    // const employeeList = ['Don']; <option value="Vanessa">Vanessa</option>
    const { user } = useContext(AuthContext);
    const employeeList = user?.email === 'smit@readysteadyfinance.co.uk' ? ['Becher', 'Carl', 'Don', 'Janne', 'Khymir', 'John', 'Krizel'] : ['Jon', 'Smit', 'Mark', 'Becher', 'Carl', 'Don', 'Janne', 'Khymir', 'John', 'Krizel'];
    const [renderCount, setRenderCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const currentDate = new Date();
    const currentMonthName = monthNames[currentDate.getMonth()];
    const [monthlyData, setMonthlyData] = useState([]);
    const [qaSummeryData, setQaSummeryData] = useState([]);
    const [qaMSummeryData, setQaMSummeryData] = useState([]);
    const [sickLeaveList, setSickLeaveList] = useState([]);
    const [monthlySummerySelectedEmplyee, setMonthlySummerySelectedEmplyee] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const [editQAReport, setEditQAReport] = useState(false);
    const [filterbyMonth, setFilterbyMonth] = useState(currentDate.getMonth());

    const openPopup = () => {
        setShowPopup(true);
    };

    const closePopup = (f) => {
        f(false);
    };

    const onFilterByMonthChange = (e) => {
        setFilterbyMonth(e.target.value);
    }

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [employeeFilter, setEmployeeFilter] = useState('');

    const handleFromDateChange = (e) => {
        setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
    };

    const handleEmployeeFilterChange = (e) => {
        setEmployeeFilter(e.target.value);
    };

    const sortedData = monthlyData.sort((a, b) => new Date(b.date) - new Date(a.date));

    const filteredData = sortedData.filter(row => {
        // Filter by from date
        if (fromDate && new Date(row.date) < new Date(fromDate)) {
            return false;
        }
        // Filter by to date
        if (toDate && new Date(row.date) > new Date(toDate)) {
            return false;
        }
        // Filter by employee
        if (employeeFilter && row.employee.toLowerCase() !== employeeFilter.toLowerCase()) {
            return false;
        }
        return true;
    });


    const totalSales = (eachEmployee) => {
        return parseInt(qaMSummeryData.find(entry => entry.employee === eachEmployee)?.sales || 0)
    }



    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER_URL}/qa-report`)
            .then(response => response.json())
            .then(data => {
                // Call a function to handle the loan data
                setMonthlyData(data);
                setIsLoading(false); // update loading state
            })
            .catch(error => {
                console.error('Error:', error);
                // Handle the error or display an error message
            });
        fetch(`${process.env.REACT_APP_SERVER_URL}/qa-summery-monthly/GETALL/${filterbyMonth}`)
            .then(response => response.json())
            .then(data => {
                // Call a function to handle the loan data
                setQaMSummeryData(data);
                setIsLoading(false); // update loading state
            })
            .catch(error => {
                console.error('Error:', error);
                // Handle the error or display an error message
            });
        // Make a request for a user with a given ID
        axios.get(`${process.env.REACT_APP_SERVER_URL}/sick-leave/GETALL`)
            .then((res) => {
                setSickLeaveList(res.data);
            })
            .catch(function (error) {
                // handle error
                console.log(error);
            })
            .finally(function () {
                // always executed
            });
    }, [renderCount, filterbyMonth]);

    const handleAddData = (e) => {
        e.preventDefault();
        const data = {
            date: e.target.date.value,
            employee: e.target.employee.value,
            calls: parseInt(e.target.calls.value),
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/qa/weekly-data`, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                setRenderCount(prevCount => prevCount + 1);
                setShowPopup(false);
            })
            .catch(error => {
                console.error('Error:', error);
            });

        // e.target.reset();
    };

    const handleEditData = (e) => {
        e.preventDefault();
        const data = {
            sales: e.target.sales.value
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/qaMonthlySummery/${monthlySummerySelectedEmplyee}/${filterbyMonth}`, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => {
                setRenderCount(prevCount => prevCount + 1);
                setEditQAReport(false);
                setMonthlySummerySelectedEmplyee("");
            })
            .catch(error => {
                console.error('Error:', error);
            });

        e.target.reset();
    };



    useEffect(() => {
        const monthlySummeryData = monthlyData.filter(entry => {
            const dateObject = new Date(entry.date);
            return dateObject.getMonth() === parseInt(filterbyMonth);
        });
        setQaSummeryData(monthlySummeryData);
    }, [filterbyMonth, monthlyData])

    const getTotalCallsByEmployee = (employee) => {
        return qaSummeryData
            .filter(entry => entry.employee === employee)
            .reduce((total, entry) => total + entry.calls, 0);
    };

    const calculateRatio = (eachEmployee) => {
        const data = qaMSummeryData.find(entry => entry.employee === eachEmployee);
        if (data) {
            const newCount = parseInt(data.new);
            const renewCount = parseInt(data.renew);
            const total = newCount + renewCount;
            return total !== 0 ? Math.round((newCount / total) * 100) : 0;
        } else {
            return 0;
        }
    };
    const calculateTotalFunding = (eachEmployee) => {
        return (parseInt(qaMSummeryData.find(entry => entry.employee === eachEmployee)?.renew || 0) + parseInt(qaMSummeryData.find(entry => entry.employee === eachEmployee)?.new || 0))
    }
    const totallSickLeave = (eachEmployee) => {
        return (sickLeaveList.filter(item => item.name === eachEmployee && new Date(item.from).getMonth() == filterbyMonth).length) * 10 || 0
    }

    const calculateRating = (eachEmployee) => {
        const totallFunded = calculateTotalFunding(eachEmployee);
        const ratio = calculateRatio(eachEmployee);
        const totalCalls = getTotalCallsByEmployee(eachEmployee);
        const sickLeave = totallSickLeave(eachEmployee);

        const maxFunded = 1000000; // Maximum points
        const maxCalls = 12000; // Maximum total calls
        const maxRatio = 80; // Maximum ratio

        // Calculate the percentage contribution of each component
        const pointsPercentage = (totallFunded / maxFunded) * 30; // 30% of total ratings
        const callsPercentage = (totalCalls / maxCalls) * 30; // 30% of total ratings
        const ratioPercentage = (parseInt(ratio) >= 80 ? 80 : parseInt(ratio)) / maxRatio * 40; // 40% of total ratings

        // Calculate total rating
        const totalRating = (pointsPercentage + callsPercentage + ratioPercentage) - sickLeave;

        // Ensure the total rating does not exceed 100%
        return Math.min(Math.floor(totalRating), 100);
    };

    const calculateQARating = (tc, ts, sickLeaves) => {
        const maxCallRating = 50;
        const maxSalesRating = 50;

        const totalCalls = tc;
        const sales = ts;
        // const totalCalls = tc > 2000 ? 2000 : tc;
        // const sales = ts > 1000000 ? 1000000 : ts;

        // Cap the call and sales ratings at their maximum percentages
        const callRating = (totalCalls / 2000) * maxCallRating;
        const salesRating = (sales / 500000) * maxSalesRating;
        const sickLeaveDeduction = sickLeaves * 5;

        let qaRating = callRating + salesRating - sickLeaveDeduction;

        // Ensure the QA Rating is not negative
        if (qaRating < 0) {
            qaRating = 0;
        }

        return Math.round(qaRating);
    };

    function calculateBonus(rating) {
        const points = rating;

        if (points < 100) {
            return 0;
        } else if (points >= 100 && points < 120) {
            return 100;
        } else if (points >= 120 && points < 140) {
            return 200;
        } else if (points >= 140 && points < 160) {
            return 300;
        } else if (points >= 160 && points < 180) {
            return 400;
        } else if (points >= 180 && points < 200) {
            return 500;
        } else if (points >= 200 && points < 220) {
            return 600;
        } else if (points >= 220 && points < 240) {
            return 700;
        } else if (points >= 240 && points < 260) {
            return 800;
        } else if (points >= 260 && points < 280) {
            return 900;
        } else if (points >= 280) {
            return 1000;
        } else {
            return 0;
        }
    }

    if (isLoading) {
        return (
            <div className='font-bold text-4xl flex h-screen justify-center items-center'>
                <PacmanLoader color="#e63a27" />
            </div>
        );
    }

    return (
        <div className='mt-5'>
            <div className='flex gap-4 mb-5'>
                <div>
                    <select onChange={onFilterByMonthChange} name='month' className="input input-sm mr-3 w-auto border-2 border-base-200 rounded-lg text-xs">
                        <option value={currentDate.getMonth()} defaultValue>Default - {currentMonthName}</option>
                        <option value="0">January</option>
                        <option value="1">February</option>
                        <option value="2">March</option>
                        <option value="3">April</option>
                        <option value="4">May</option>
                        <option value="5">June</option>
                        <option value="6">July</option>
                        <option value="7">August</option>
                        <option value="8">September</option>
                        <option value="9">October</option>
                        <option value="10">November</option>
                        <option value="11">December</option>
                    </select>
                    <div className='border border-base-300 rounded-lg text-sm mt-2 overflow-hidden'>
                        <div className='flex bg-base-200 divide-x divide-base-300'>
                            <span className='p-3 w-20'>Names</span>
                            <span className='p-3 w-40'>Base Numbers</span>
                            {
                                user?.email !== 'smit@readysteadyfinance.co.uk' && <span className='p-3 w-40'>QA Summary</span>
                            }
                        </div>
                        <div className='divide-y divide-base-300'>
                            {employeeList.map(eachEmployee => <div onClick={() => {
                                setEditQAReport(true);
                                setMonthlySummerySelectedEmplyee(eachEmployee);
                            }} className='flex divide-x divide-base-300 hover:bg-base-200 cursor-pointer'>
                                <span className='p-3 w-20'>{eachEmployee}</span>
                                <span className='p-3 w-40'>
                                    <p>Total Calls: {getTotalCallsByEmployee(eachEmployee).toLocaleString()}</p>
                                    {
                                        user?.email !== 'smit@readysteadyfinance.co.uk' && <p>Sales: {totalSales(eachEmployee).toLocaleString()}</p>
                                    }
                                    {
                                        user?.email !== 'smit@readysteadyfinance.co.uk' && <p>Sick Leaves: {sickLeaveList.filter(item => item.name === eachEmployee && new Date(item.from).getMonth() == filterbyMonth).length}</p>
                                    }
                                </span>
                                {
                                    user?.email !== 'smit@readysteadyfinance.co.uk' && <span className='p-3 w-40'>
                                        <p>QA Rating: {calculateQARating(parseInt(getTotalCallsByEmployee(eachEmployee)), parseInt(totalSales(eachEmployee)), sickLeaveList.filter(item => item.name === eachEmployee && new Date(item.from).getMonth() == filterbyMonth).length)}%</p>
                                        <p>QA Bonus: £ {totalSales(eachEmployee) >= 100000 ? calculateBonus(calculateQARating(parseInt(getTotalCallsByEmployee(eachEmployee)), parseInt(totalSales(eachEmployee)), sickLeaveList.filter(item => item.name === eachEmployee && new Date(item.from).getMonth() == filterbyMonth).length)).toLocaleString() : 0}</p>
                                    </span>
                                }
                            </div>)}
                        </div>
                    </div>
                </div>

                {/* ---------------------------------------------------------------------------------------------------------------------- */}

                <div className='grow'>
                    <div className='pl-0 pb-2 flex justify-end gap-5'>
                        <div>
                            <input
                                type="date"
                                value={fromDate}
                                onChange={handleFromDateChange}
                                className='input input-sm bg-base-200'
                            />
                        </div>
                        <div>
                            <input
                                type="date"
                                value={toDate}
                                onChange={handleToDateChange}
                                className='input input-sm bg-base-200'
                            />
                        </div>

                        <div>
                            <select
                                value={employeeFilter}
                                onChange={handleEmployeeFilterChange}
                                className='input input-sm bg-base-200'
                            >
                                <option value="">Select an option</option>
                                {user?.email !== 'smit@readysteadyfinance.co.uk' && <option value="Jon">Jon</option>}
                                {user?.email !== 'smit@readysteadyfinance.co.uk' && <option value="Smit">Smit</option>}
                                {user?.email !== 'smit@readysteadyfinance.co.uk' && <option value="Mark">Mark</option>}
                                <option value="Becher">Becher</option>
                                <option value="Don">Don</option>
                                <option value="Carl">Carl</option>
                                <option value="Janne">Janne</option>
                                <option value="Krizel">Krizel</option>
                                <option value="John">John</option>
                                <option value="Khymir">Khymir</option>
                            </select>
                        </div>
                        <div>
                            {user?.email !== 'smit@readysteadyfinance.co.uk' && <button onClick={openPopup} className='btn btn-sm bg-primary text-white text-xs'>Add New Data</button>}
                        </div>
                    </div>
                    <div className='border border-base-300 rounded-lg text-sm h-[79vh] overflow-auto'>
                        <div className='grid grid-cols-4 bg-base-200 divide-x divide-base-300'>
                            <span className='p-3'>No.</span>
                            <span className='p-3'>Date</span>
                            <span className='p-3'>Name</span>
                            <span className='p-3'>Calls</span>
                        </div>
                        <div className='divide-y divide-base-300'>
                            {user?.email !== 'smit@readysteadyfinance.co.uk' && filteredData.map((row, i) => (
                                <div key={i} className='grid grid-cols-4 divide-x divide-base-300 hover:bg-base-200 cursor-pointer'>
                                    <span className='p-3'>{i + 1}</span>
                                    <span className='p-3'>{new Date(row.date).toLocaleDateString('en-GB', {
                                        day: 'numeric',
                                        month: 'numeric',
                                        year: 'numeric',
                                    })}</span>
                                    <span className='p-3'>{row.employee}</span>
                                    <span className='p-3'>{row.calls}</span>
                                </div>
                            ))}
                            {user?.email === 'smit@readysteadyfinance.co.uk' && filteredData
                                .filter(row => ['Khymir', 'John', 'Krizel', 'Janne', 'Charmin', 'Becher', 'Carl', 'Don'].includes(row.employee))
                                .map((row, i) => (
                                    <div key={i} className='grid grid-cols-4 divide-x divide-base-300 hover:bg-base-200 cursor-pointer'>
                                        <span className='p-3'>{i + 1}</span>
                                        <span className='p-3'>{new Date(row.date).toLocaleDateString('en-GB', {
                                            day: 'numeric',
                                            month: 'numeric',
                                            year: 'numeric',
                                        })}</span>
                                        <span className='p-3'>{row.employee}</span>
                                        <span className='p-3'>{row.calls}</span>
                                    </div>
                                ))
                            }

                        </div>
                    </div>
                </div>

            </div>

            {/* ---------------------------------------------------------------------------------------------------------------------- */}

            {showPopup && (
                <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex items-center justify-center">
                    <form onSubmit={e => handleAddData(e)} className="bg-base-200 p-8 rounded-xl w-[650px] shadow-md">
                        <h2 className="text-lg font-semibold">Add New Datas</h2>
                        <div className="grid grid-cols-3 gap-x-2">
                            <div>
                                <label htmlFor="month" className='label pb-0 mt-1'>Date:</label>
                                <input
                                    type="date"
                                    id="date"
                                    name="date"
                                    className="input w-full"
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="employee" className='label pb-0 mt-1'>Employee:</label>
                                <select name='employee' className="input w-full" required>
                                    <option value="Jon">Jon</option>
                                    <option value="Smit">Smit</option>
                                    <option value="Mark">Mark</option>
                                    <option value="Becher">Becher</option>
                                    <option value="Don">Don</option>
                                    <option value="Carl">Carl</option>
                                    <option value="Janne">Janne</option>
                                    <option value="Krizel">Krizel</option>
                                    <option value="John">John</option>
                                    <option value="Khymir">Khymir</option>
                                </select>
                            </div>
                            <div>
                                <label htmlFor="jon" className='label pb-0 mt-1'>Calls:</label>
                                <input
                                    type="number"
                                    id="calls"
                                    name="calls"
                                    defaultValue={0}
                                    className="input w-full"
                                    required
                                />
                            </div>
                        </div>
                        <div className="flex justify-between mt-5">
                            <button type='submit' className="mr-2 button">Submit</button>
                            <button type='button' onClick={() => closePopup(setShowPopup)} className='button !bg-gray-400'>Cancel</button>
                        </div>
                    </form>
                </div>
            )}
            {editQAReport && (
                <div className="fixed top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50 flex items-center justify-center">
                    <form onSubmit={e => handleEditData(e)} className="bg-base-200 p-8 rounded-xl w-[650px] shadow-md">
                        <h2 className="text-lg font-semibold">Edit Summery:</h2>
                        <div>
                            <div>
                                <label htmlFor="sales" className='label pb-0 mt-1'>Sales:</label>
                                <input
                                    type="number"
                                    id="sales"
                                    name="sales"
                                    defaultValue={0}
                                    className="input w-full"
                                />
                            </div>
                            {/* <div>
                                <label htmlFor="renew" className='label pb-0 mt-1'>Renew:</label>
                                <input
                                    type="number"
                                    id="renew"
                                    name="renew"
                                    defaultValue={0}
                                    className="input w-full"
                                />
                            </div> */}
                        </div>
                        <div className="flex justify-between mt-5">
                            <button type='submit' className="mr-2 button">Submit</button>
                            <button type='button' onClick={() => closePopup(setEditQAReport)} className='button !bg-gray-400'>Cancel</button>
                        </div>
                    </form>
                </div>
            )}
        </div>
    )
}

export default QaBonus
