import React from 'react'
import { NavLink } from 'react-router-dom'
import homeIcon from "../../assets/home.svg"
import logo from "../../assets/image.png"

const CustomerSidenav = () => {
    return (
        <aside className='w-full max-w-[300px] h-screen p-6 hidden md:block'>
            <p className='text-base font-semibold'>
                <img src={logo} alt="RS Portal Dashboard" className='w-full rounded-lg' style={{ boxShadow: "0 20px 27px 0 rgba(0, 0, 0, 0.05)" }} />
                {/* RS Portal Dashboard */}
            </p>
            <div className='divider'></div>
            <div>
                <div>
                    <NavLink to='/customer/dashboard' className='sidenav-btn'>
                        <div className='w-7'>
                            <img src={homeIcon} className='h-6' alt="Home icon" />
                        </div>
                        <span className='text-[#344767] font-[600] text-[14px]'>Dashboard</span>
                    </NavLink>
                    {/* <NavLink to='/customer/profile' className='sidenav-btn'>
                        <div className='w-7'>
                            <img src={userIcon} className='h-6' alt="Home icon" />
                        </div>
                        <span className='text-[#344767] font-[600] text-[14px]'>Profile</span>
                    </NavLink> */}
                    {/* <Link to='/dashboard/user' className='flex items-center cursor-pointer gap-3 p-3 bg-[#fff] rounded-lg mb-4' style={{boxShadow:"0 20px 27px 0 rgba(0, 0, 0, 0.05)"}}>
                <div className='bg-rsf p-2 rounded-lg'>
                  <span className='text-[#fff]'><AiOutlineUserAdd /></span>
                </div>
                <span className='text-[#344767] font-[600] text-[14px]'>User</span>
              </Link> */}
                </div>
            </div>
            <div className='border border-[#e63a27] p-5 text-sm rounded-lg fixed bottom-5' style={{ boxShadow: "0 20px 27px 0 rgba(0, 0, 0, 0.05)" }}>
                <b>Have a question?</b>
                <p>We're here to help.</p>
                <a href="mailto:info@readysteadyfinance.co.uk">info@readysteadyfinance.co.uk</a>
            </div>
        </aside>
    )
}

export default CustomerSidenav