import React from 'react'

const KanbanStatuscolumSkeleton = () => {
    return (
        <div className='min-w-[300px] h-[calc(100vh-200px)] flex flex-col pl-3 border-l border-dashed border-[#b7b7b7]'>
            <div className="text-center text-4xl font-light text-gray-400 pb-3 flex items-center justify-center animate-pulse">
                <div className="h-12 bg-base-200 rounded w-full"></div>
            </div>
            <div className="text-center text-4xl font-light text-gray-400 pb-5 pt-2 flex items-center justify-center animate-pulse">
                <div className=" rounded">£0</div>
            </div>
            <div className="flex min-h-20 flex-grow flex-col gap-4 p-2 overflow-x-hidden overflow-y-auto">
                <div className="rounded-lg bg-base-100 w-[264px] border-2 border-base animate-pulse">
                    <div className="p-4">
                        <div className="h-4 bg-base-300 rounded w-1/3 mb-2"></div>
                        <div className="h-4 bg-base-300 rounded w-1/2 mb-2"></div>
                        <div className="flex flex-row items-center mt-2">
                            <div className="rounded-full h-8 w-8 bg-base-300 mr-3"></div>
                            <div className="h-4 bg-base-300 rounded w-1/3"></div>
                        </div>
                        <div className="flex justify-between mt-3">
                            <div className="h-4 bg-base-300 rounded w-1/4"></div>
                            <div className="h-4 bg-base-300 rounded w-1/4"></div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 text-sm p-2 border-t">
                        <div className='flex justify-center items-center border-r py-1'>
                            <div className="rounded-full h-6 w-6 bg-base-300"></div>
                        </div>
                        <div className='flex justify-center items-center'>
                            <div className="rounded-full h-6 w-6 bg-base-300"></div>
                        </div>
                    </div>
                </div>
                <div className="rounded-lg bg-base-100 w-[300px] border-2 border-base animate-pulse">
                    <div className="p-4">
                        <div className="h-4 bg-base-300 rounded w-1/3 mb-2"></div>
                        <div className="h-4 bg-base-300 rounded w-1/2 mb-2"></div>
                        <div className="flex flex-row items-center mt-2">
                            <div className="rounded-full h-8 w-8 bg-base-300 mr-3"></div>
                            <div className="h-4 bg-base-300 rounded w-1/3"></div>
                        </div>
                        <div className="flex justify-between mt-3">
                            <div className="h-4 bg-base-300 rounded w-1/4"></div>
                            <div className="h-4 bg-base-300 rounded w-1/4"></div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 text-sm p-2 border-t">
                        <div className='flex justify-center items-center border-r py-1'>
                            <div className="rounded-full h-6 w-6 bg-base-300"></div>
                        </div>
                        <div className='flex justify-center items-center'>
                            <div className="rounded-full h-6 w-6 bg-base-300"></div>
                        </div>
                    </div>
                </div>
                <div className="rounded-lg bg-base-100 w-[300px] border-2 border-base animate-pulse">
                    <div className="p-4">
                        <div className="h-4 bg-base-300 rounded w-1/3 mb-2"></div>
                        <div className="h-4 bg-base-300 rounded w-1/2 mb-2"></div>
                        <div className="flex flex-row items-center mt-2">
                            <div className="rounded-full h-8 w-8 bg-base-300 mr-3"></div>
                            <div className="h-4 bg-base-300 rounded w-1/3"></div>
                        </div>
                        <div className="flex justify-between mt-3">
                            <div className="h-4 bg-base-300 rounded w-1/4"></div>
                            <div className="h-4 bg-base-300 rounded w-1/4"></div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 text-sm p-2 border-t">
                        <div className='flex justify-center items-center border-r py-1'>
                            <div className="rounded-full h-6 w-6 bg-base-300"></div>
                        </div>
                        <div className='flex justify-center items-center'>
                            <div className="rounded-full h-6 w-6 bg-base-300"></div>
                        </div>
                    </div>
                </div>
                <div className="rounded-lg bg-base-100 w-[300px] border-2 border-base animate-pulse">
                    <div className="p-4">
                        <div className="h-4 bg-base-300 rounded w-1/3 mb-2"></div>
                        <div className="h-4 bg-base-300 rounded w-1/2 mb-2"></div>
                        <div className="flex flex-row items-center mt-2">
                            <div className="rounded-full h-8 w-8 bg-base-300 mr-3"></div>
                            <div className="h-4 bg-base-300 rounded w-1/3"></div>
                        </div>
                        <div className="flex justify-between mt-3">
                            <div className="h-4 bg-base-300 rounded w-1/4"></div>
                            <div className="h-4 bg-base-300 rounded w-1/4"></div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 text-sm p-2 border-t">
                        <div className='flex justify-center items-center border-r py-1'>
                            <div className="rounded-full h-6 w-6 bg-base-300"></div>
                        </div>
                        <div className='flex justify-center items-center'>
                            <div className="rounded-full h-6 w-6 bg-base-300"></div>
                        </div>
                    </div>
                </div>
                <div className="rounded-lg bg-base-100 w-[300px] border-2 border-base animate-pulse">
                    <div className="p-4">
                        <div className="h-4 bg-base-300 rounded w-1/3 mb-2"></div>
                        <div className="h-4 bg-base-300 rounded w-1/2 mb-2"></div>
                        <div className="flex flex-row items-center mt-2">
                            <div className="rounded-full h-8 w-8 bg-base-300 mr-3"></div>
                            <div className="h-4 bg-base-300 rounded w-1/3"></div>
                        </div>
                        <div className="flex justify-between mt-3">
                            <div className="h-4 bg-base-300 rounded w-1/4"></div>
                            <div className="h-4 bg-base-300 rounded w-1/4"></div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 text-sm p-2 border-t">
                        <div className='flex justify-center items-center border-r py-1'>
                            <div className="rounded-full h-6 w-6 bg-base-300"></div>
                        </div>
                        <div className='flex justify-center items-center'>
                            <div className="rounded-full h-6 w-6 bg-base-300"></div>
                        </div>
                    </div>
                </div>
                <div className="rounded-lg bg-base-100 w-[300px] border-2 border-base animate-pulse">
                    <div className="p-4">
                        <div className="h-4 bg-base-300 rounded w-1/3 mb-2"></div>
                        <div className="h-4 bg-base-300 rounded w-1/2 mb-2"></div>
                        <div className="flex flex-row items-center mt-2">
                            <div className="rounded-full h-8 w-8 bg-base-300 mr-3"></div>
                            <div className="h-4 bg-base-300 rounded w-1/3"></div>
                        </div>
                        <div className="flex justify-between mt-3">
                            <div className="h-4 bg-base-300 rounded w-1/4"></div>
                            <div className="h-4 bg-base-300 rounded w-1/4"></div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 text-sm p-2 border-t">
                        <div className='flex justify-center items-center border-r py-1'>
                            <div className="rounded-full h-6 w-6 bg-base-300"></div>
                        </div>
                        <div className='flex justify-center items-center'>
                            <div className="rounded-full h-6 w-6 bg-base-300"></div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>

    )
}

export default KanbanStatuscolumSkeleton