import React from 'react'

function RequestLoanPopup({ handleStl, handleLtl, handleIfinance, handleInvestments, openRenewalMCA, openRenewalLoan }) {
  return (
    <div className='grid grid-cols-2 gap-2'>
        <button onClick={handleStl} className="button">MCA</button>
        <button onClick={handleLtl} className="button">Business Loan</button>
        <button onClick={handleIfinance} className="button">Invoice Finance</button>
        <button onClick={handleInvestments} className="button">Grants</button>
        <button onClick={openRenewalLoan} className="button">Renewal Loan</button>
        <button onClick={openRenewalMCA} className="button">Renewal MCA</button>
    </div>
  )
}

export default RequestLoanPopup