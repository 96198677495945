import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext';
import pdf from "../../assets/terms.pdf"

const Register = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const { createAccount, updateUserInfo } = useContext(AuthContext);
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    const handleTerms = () => {
        window.terms.showModal()
    };

    const registerAccount = (event) => {
        event.preventDefault();
        const userInfo = event.target;
        const displayName = userInfo.name.value;
        const email = userInfo.email.value;
        const pass = userInfo.password.value;

        console.log(event);


        createAccount(email, pass)
            .then(res => {
                // console.log(res.user.uid);
                updateUserInfo({ displayName })
                    .then(response => {
                        console.log(res.user.uid);
                        fetch(`${process.env.REACT_APP_SERVER_URL}/update-user`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({ displayName, email, pass, "createdDate": new Date().toISOString() })
                        })
                            .then(response => response.json())
                            .then(result => {
                                console.log('User updated:', result);
                                // Handle the response or perform any necessary actions
                                setUsers([...users, { displayName, email, pass, "createdDate": new Date().toISOString() }])
                                navigate('/');
                            })
                            .catch(error => {
                                console.error('Error:', error);
                                // Handle the error or display an error message
                            });
                    })
                    .catch((error) => {
                        // An error occurred
                        // ...
                    });
                // console.log(user)
            })
            .catch((error) => {
                // const errorCode = error.code;
                const errorMessage = error.message;
                console.error(errorMessage);
            });
    }


    return (
        <div>
            <div className='flex h-screen justify-center overflow-x-hidden'>
                <div className='basis-1/4 flex h-screen items-center '>
                    <div className='basis-full'>
                        <div className='w-[100%] 2xl:w-[80%]'>
                            <div className='mb-[30px]'>
                                <div>
                                    <h3 className='gradient-text-rsf'>Sign Up</h3>
                                </div>
                                <p className='custom-subtitle-rsf'>Enter your name, email and password to sign up</p>
                            </div>
                            <div>
                                <form onSubmit={registerAccount}>
                                    <div>
                                        <label className='custom-label-rsf mb-3 block'>Name</label>
                                        <div className='custom-input-div-rsf'>
                                            <input className='custom-input-rsf' type="text" name='name' required />
                                        </div>
                                    </div>
                                    <div className='mt-5'>
                                        <label className='custom-label-rsf mb-3 block'>Email</label>
                                        <div className='custom-input-div-rsf'>
                                            <input className='custom-input-rsf' type="email" name='email' required />
                                        </div>
                                    </div>
                                    <div className='mt-5'>
                                        <label className='custom-label-rsf mb-3 block'>Password</label>
                                        <div className='custom-input-div-rsf'>
                                            <input className='custom-input-rsf' type="password" name='password' required />
                                        </div>
                                    </div>
                                    <div className='text-sm flex gap-2 mt-3'>
                                        <input
                                            type="checkbox"
                                            className="checkbox checkbox-sm"
                                            checked={isChecked}
                                            onChange={handleCheckboxChange}
                                        />
                                        <p>
                                            By clicking this checkbox, you agree to our Terms and Conditions. <Link target='_blank' to="https://firebasestorage.googleapis.com/v0/b/readysteady-finance.appspot.com/o/terms.pdf?alt=media&token=e30d6147-3a54-4af4-a496-58cbd5014ac0" className='color-rsf'>Terms & Conditions</Link>
                                        </p>
                                    </div>
                                    <div>
                                        <button type='submit' disabled={!isChecked} className={`button mt-4 w-full ${!isChecked ? "!bg-[#ff998e]" : ""}`}>SIGN UP</button>
                                    </div>


                                    <div className='text-sm text-center mt-4 text-[#67748E]'>
                                        Already an account? <Link to="/" className='color-rsf font-semibold'> Sign In</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='custom-login-page-img-box basis-[42%]'>
                    <div className='custom-login-page-img-inner-box'>
                        <div className='custom-login-page-img'></div>
                    </div>
                </div>
            </div>
            <dialog id="terms" className="modal w-[100%] max-w-[100%] mx-auto rounded">
                <div method="dialog" className="modal-box w-[900px] max-w-[900px] p-10">

                </div>
            </dialog>
        </div>
    )
}

export default Register