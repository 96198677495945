import React, { useContext, useEffect, useState } from 'react';
import { FaArrowLeft, FaCheck } from "react-icons/fa6";
import { useNavigate, useParams } from 'react-router-dom';
import userplaceholder from './../../assets/userplaceholder.png';
import { AiOutlineSecurityScan, AiOutlineUser } from "react-icons/ai";
import { PiPencilSimpleDuotone } from 'react-icons/pi';
import { RxCross2 } from "react-icons/rx";
import { PacmanLoader } from 'react-spinners';
import { AuthContext } from '../../context/AuthContext';
import { getDatabase, ref, onValue } from "firebase/database";
import axios from 'axios';


const UserManagement = () => {
    const serverURL = `${process.env.REACT_APP_SERVER_URL}`;
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState();
    const [loading, setLoading] = useState(false);
    const [userPresence, setUserPresence] = useState('offline');
    const { id } = useParams();


    const disableUser = () => {
        axios.post(`${serverURL}/account-disable/${userInfo?.uid}`, {
            email: user.email
        }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(response => {
                return response.data;
            })
            .then(data => {
                console.log(data);
                setUserInfo(prevState => ({
                    ...prevState,
                    disabled: true
                }))
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    const enableUser = () => {
        axios.post(`${serverURL}/account-enable/${userInfo?.uid}`, {
            email: user.email
        }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(response => {
                return response.data;
            })
            .then(data => {
                console.log(data);
                setUserInfo(prevState => ({
                    ...prevState,
                    disabled: false
                }))
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    useEffect(() => {
        const db = getDatabase();
        const userStatusRef = ref(db, `adminUsers/${userInfo?.uid}/state`);

        console.log(userInfo);
        

        const unsubscribe = onValue(userStatusRef, (snapshot) => {
            const data = snapshot.val();
            console.log(data);

            setUserPresence(data);
        });

        // Cleanup subscription on unmount
        return () => {
            unsubscribe();
        };
    }, [userInfo?.uid]);

    useEffect(() => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_SERVER_URL}/api/v2/user/${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ email: user.email })
        })
            .then(response => response.json())
            .then(data => {
                setUserInfo(data);
                console.log(data);

                setLoading(false);
            })
            .catch(error => {
                console.error('Error:', error);
                setLoading(false);
            });
    }, [])

    if (loading) {
        return <div className='text-4xl mt-[35vh] flex justify-center items-center'>
            <PacmanLoader color="#e63a27" />
        </div>
    }


    return (
        <div className='mb-10'>
            <button onClick={() => navigate(-1)} className="flex items-center gap-3 mt-10">
                <FaArrowLeft /> <span>Accounts</span>
            </button>
            <div className='mt-5 flex justify-between items-center'>
                <div className='flex justify-start items-center gap-5'>
                    <img className='w-16 rounded-full' src={userplaceholder} alt="" />
                    <div>
                        <p className='text-4xl flex items-center gap-3'>
                            {userInfo?.displayName || 'Name'}
                            {
                                userPresence === "online" ?
                                    <span className='bg-base-300 font-semibold text-sm capitalize px-3 py-1 rounded-full flex items-center gap-2'>Online <span className='h-2 w-2 bg-green-500 inline-block rounded-full'></span></span>
                                    :
                                    <span className='bg-base-300 font-semibold text-sm capitalize px-3 py-1 rounded-full flex items-center gap-2'>Offline <span className='h-2 w-2 bg-red-500 inline-block rounded-full'></span></span>
                            }
                        </p>
                        <p>{userInfo?.email || 'Email'}</p>
                    </div>
                </div>
                <div>
                    <button className='btn hover:bg-black bg-primary text-white'>Action</button>
                </div>
            </div>
            <div className='flex mt-10 gap-5'>
                <div className='basis-1/3'>
                    <div className='bg-base-300 rounded-xl p-8'>
                        <div className='flex justify-between items-center mb-2'>
                            <div className='flex justify-start items-center gap-5'>
                                <span className='text-3xl'><AiOutlineUser /></span>
                                <div>
                                    <p>Basic details</p>
                                </div>
                            </div>
                            <div>
                                <button className='btn bg-base-300 border-base-300 hover:bg-base-200 text-2xl'><PiPencilSimpleDuotone /></button>
                            </div>
                        </div>
                        <div className='py-4 border-b border-base-100'>
                            <p className='text-sm'>Account ID</p>
                            <p className='text-base font-mono'>{userInfo?._id || <span className='bg-base-100 px-5 font-sans py-1 mt-2 rounded-lg'>No Id Added</span>}</p>
                        </div>
                        <div className='py-4 border-b border-base-100'>
                            <p className='text-sm'>Name</p>
                            <p className='text-base font-sans'>{userInfo?.displayName || <span className='bg-base-100 px-5 font-sans py-1 mt-2 rounded-lg'>No Name Added</span>}</p>
                        </div>
                        <div className='py-4 border-b border-base-100'>
                            <p className='text-sm'>Email</p>
                            <p className='text-base font-sans'>{userInfo?.email || <span className='bg-base-100 px-5 font-sans py-1 mt-2 rounded-lg'>No Email Added</span>}</p>
                        </div>
                        <div className='py-4 border-b border-base-100'>
                            <p className='text-sm'>Phone Number</p>
                            <p className='text-base font-mono'>{userInfo?.phone || <span className='bg-base-100 px-5 font-sans py-1 mt-2 rounded-lg'>No Number Added</span>}</p>
                        </div>
                        <div className='pt-4'>
                            <p className='text-sm'>Role</p>
                            <p className='text-base'>{userInfo?.role || <span className='bg-base-100 px-5 font-sans py-1 mt-2 rounded-lg'>No Role Added</span>}</p>
                        </div>
                    </div>
                    <div className='bg-base-300 rounded-xl p-8 mt-3'>
                        <div className='flex justify-start items-center gap-5'>
                            <span className='text-3xl '><AiOutlineSecurityScan /></span>
                            <div>
                                <p>Security</p>
                            </div>
                        </div>

                        {
                            userInfo?.disabled === true ? <button onClick={enableUser} className='btn bg-green-700 hover:bg-black my-5 text-white'>Enable Account</button> : <button onClick={disableUser} className='btn bg-red-700 hover:bg-black my-5 text-white'>Disable Account</button>
                        }

                        <p className='text-sm'>A deleted member cannot be restored. All data will be permanently removed.</p>
                    </div>
                </div>
                <div className='basis-2/3'>
                    <div className='bg-base-300 rounded-xl p-8'>
                        <div className='flex justify-between items-center gap-5'>
                            {/* <span className='text-3xl '><AiOutlineSecurityScan /></span> */}
                            <div>
                                <p className='text-lg font-semibold'>Allowed Status</p>
                            </div>
                            <div>
                                <button className='btn bg-base-300 border-base-300 hover:bg-base-200 text-2xl'><PiPencilSimpleDuotone /></button>
                            </div>
                        </div>
                        <div className='mt-3 flex flex-wrap gap-3'>
                            <span className="bg-base-100 font-semibold text-lg capitalize px-5 py-3 rounded-lg inline-flex items-center gap-2">
                                Initial Review <span className="bg-green-500 rounded-full text-black text-xs p-1"><FaCheck /></span>
                            </span>
                            <span className="bg-base-100 font-semibold text-lg capitalize px-5 py-3 rounded-lg inline-flex items-center gap-2">
                                Golden Data <span className="bg-red-500 rounded-full text-black text-xs p-1"><RxCross2 /></span>
                            </span>
                            <span className="bg-base-100 font-semibold text-lg capitalize px-5 py-3 rounded-lg inline-flex items-center gap-2">
                                The Great Takeover <span className="bg-green-500 rounded-full text-black text-xs p-1"><FaCheck /></span>
                            </span>
                            <span className="bg-base-100 font-semibold text-lg capitalize px-5 py-3 rounded-lg inline-flex items-center gap-2">
                                Pre-Underwriting <span className="bg-green-500 rounded-full text-black text-xs p-1"><FaCheck /></span>
                            </span>
                            <span className="bg-base-100 font-semibold text-lg capitalize px-5 py-3 rounded-lg inline-flex items-center gap-2">
                                Pre-Offer <span className="bg-green-500 rounded-full text-black text-xs p-1"><FaCheck /></span>
                            </span>
                            <span className="bg-base-100 font-semibold text-lg capitalize px-5 py-3 rounded-lg inline-flex items-center gap-2">
                                Offer <span className="bg-green-500 rounded-full text-black text-xs p-1"><FaCheck /></span>
                            </span>
                        </div>
                    </div>
                    <div className='bg-base-300 rounded-xl p-8 mt-3'>
                        <div className='flex justify-between items-center gap-5'>
                            {/* <span className='text-3xl '><AiOutlineSecurityScan /></span> */}
                            <div>
                                <p className='text-lg font-semibold'>Allowed Other User</p>
                            </div>
                            <div>
                                <button className='btn bg-base-300 border-base-300 hover:bg-base-200 text-2xl'><PiPencilSimpleDuotone /></button>
                            </div>
                        </div>
                        <div className='mt-3 flex flex-wrap gap-3'>
                            <span className="bg-base-100 font-semibold text-lg capitalize px-5 py-3 rounded-lg inline-flex items-center gap-2">
                                Wayne <span className="bg-green-500 rounded-full text-black text-xs p-1"><FaCheck /></span>
                            </span>
                            <span className="bg-base-100 font-semibold text-lg capitalize px-5 py-3 rounded-lg inline-flex items-center gap-2">
                                Peter <span className="bg-red-500 rounded-full text-black text-xs p-1"><RxCross2 /></span>
                            </span>
                            <span className="bg-base-100 font-semibold text-lg capitalize px-5 py-3 rounded-lg inline-flex items-center gap-2">
                                Smit <span className="bg-green-500 rounded-full text-black text-xs p-1"><FaCheck /></span>
                            </span>
                            <span className="bg-base-100 font-semibold text-lg capitalize px-5 py-3 rounded-lg inline-flex items-center gap-2">
                                Jon <span className="bg-green-500 rounded-full text-black text-xs p-1"><FaCheck /></span>
                            </span>
                            <span className="bg-base-100 font-semibold text-lg capitalize px-5 py-3 rounded-lg inline-flex items-center gap-2">
                                Myca <span className="bg-green-500 rounded-full text-black text-xs p-1"><FaCheck /></span>
                            </span>
                            <span className="bg-base-100 font-semibold text-lg capitalize px-5 py-3 rounded-lg inline-flex items-center gap-2">
                                Millie <span className="bg-green-500 rounded-full text-black text-xs p-1"><FaCheck /></span>
                            </span>
                        </div>
                    </div>
                    <div className='bg-base-300 rounded-xl p-8 mt-3'>
                        <div className='flex justify-between items-center gap-5'>
                            {/* <span className='text-3xl '><AiOutlineSecurityScan /></span> */}
                            <div>
                                <p className='text-lg font-semibold'>Allowed Lender</p>
                            </div>
                            <div>
                                <button className='btn bg-base-300 border-base-300 hover:bg-base-200 text-2xl'><PiPencilSimpleDuotone /></button>
                            </div>
                        </div>
                        <div className='mt-3 flex flex-wrap gap-3'>
                            <span className="bg-base-100 font-semibold text-lg capitalize px-5 py-3 rounded-lg inline-flex items-center gap-2">
                                MCA <span className="bg-green-500 rounded-full text-black text-xs p-1"><FaCheck /></span>
                            </span>
                            <span className="bg-base-100 font-semibold text-lg capitalize px-5 py-3 rounded-lg inline-flex items-center gap-2">
                                365 <span className="bg-red-500 rounded-full text-black text-xs p-1"><RxCross2 /></span>
                            </span>
                            <span className="bg-base-100 font-semibold text-lg capitalize px-5 py-3 rounded-lg inline-flex items-center gap-2">
                                YouLend <span className="bg-green-500 rounded-full text-black text-xs p-1"><FaCheck /></span>
                            </span>
                            <span className="bg-base-100 font-semibold text-lg capitalize px-5 py-3 rounded-lg inline-flex items-center gap-2">
                                Capify <span className="bg-green-500 rounded-full text-black text-xs p-1"><FaCheck /></span>
                            </span>
                            <span className="bg-base-100 font-semibold text-lg capitalize px-5 py-3 rounded-lg inline-flex items-center gap-2">
                                Disny <span className="bg-green-500 rounded-full text-black text-xs p-1"><FaCheck /></span>
                            </span>
                            <span className="bg-base-100 font-semibold text-lg capitalize px-5 py-3 rounded-lg inline-flex items-center gap-2">
                                Shopold <span className="bg-green-500 rounded-full text-black text-xs p-1"><FaCheck /></span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserManagement