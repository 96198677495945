import React, { useContext } from 'react'
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const ForgetPassword = () => {
    const { signIn, user, setLoading, logOut, loading, resetPasswordRequest } = useContext(AuthContext);
    const navigate = useNavigate();
    const resetPassHandler = (event) => {
        event.preventDefault();
        const email = event.target.email.value;

        resetPasswordRequest(email)
            .then(() => {
                navigate('/')
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                // ..
            });
        event.target.reset();
    }
    return (
        <div>
            <div className='flex h-screen justify-center overflow-x-hidden'>
                <div className='basis-full px-10 md:px-0 mx-auto md:basis-1/4 flex h-screen items-center '>
                    <div className='basis-full'>
                        <div className='w-[100%] 2xl:w-[80%]'>
                            <div className='mb-[30px]'>
                                <div>
                                    <h3 className='gradient-text-rsf'>Forgot Your Password?</h3>
                                </div>
                                <p className='custom-subtitle-rsf'>Enter your email to send a reset link</p>
                            </div>

                            <div className='mt-6'>
                                <form onSubmit={resetPassHandler}>
                                    <div>
                                        <label className='custom-label-rsf mb-3 block'>Email</label>
                                        <div className='custom-input-div-rsf'>
                                            <input className='custom-input-rsf' type="email" name='email' required />
                                        </div>
                                    </div>
                                    <div>
                                        <button type='submit' className='button mt-[32px] w-full'>SIGN IN</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='custom-login-page-img-box basis-[42%] hidden md:block'>
                    <div className='custom-login-page-img-inner-box'>
                        <div className='custom-login-page-img'></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgetPassword