import React, { useEffect, useState } from 'react'

const useIban = (accountIds) => {
    const [accountData, setAccountData] = useState([]);
    const [obloading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchAccountNames = async () => {
            try {
                setLoading(true);
                const responses = await Promise.all(accountIds.map(async (id) => {
                    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/gocardless-account/${id}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ token: localStorage.getItem('bankToken') }) // Replace with your actual token
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }

                    const data = await response.json();
                    return { id, iban: data.iban };
                }));
                setAccountData(responses);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchAccountNames();
    }, [accountIds]);

    return { accountData, obloading, error };
}

export default useIban