import React, { useContext, useEffect, useState } from 'react';
import { PacmanLoader } from 'react-spinners';
import { AuthContext } from '../../context/AuthContext';

const QaBonusStaff = () => {
    const { user } = useContext(AuthContext);
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const currentDate = new Date();
    const currentMonthName = monthNames[currentDate.getMonth()];
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [employeeFilter, setEmployeeFilter] = useState('');
    const [qaMSummeryData, setQaMSummeryData] = useState({});
    const [qaSummeryData, setQaSummeryData] = useState([]);
    const [monthlyData, setMonthlyData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [filterbyMonth, setFilterbyMonth] = useState(currentDate.getMonth());
    const [sickLeaveData, setSickLeaveData] = useState([]);


    const handleFromDateChange = (e) => {
        setFromDate(e.target.value);
    };

    const handleToDateChange = (e) => {
        setToDate(e.target.value);
    };

    const onFilterByMonthChange = (e) => {
        setFilterbyMonth(e.target.value);
    }

    const sortedData = monthlyData.sort((a, b) => new Date(b.date) - new Date(a.date));

    const filteredData = sortedData.filter(row => {
        if (fromDate && new Date(row.date) < new Date(fromDate)) {
            return false;
        }
        if (toDate && new Date(row.date) > new Date(toDate)) {
            return false;
        }
        if (employeeFilter && row.employee.toLowerCase() !== employeeFilter.toLowerCase()) {
            return false;
        }
        return true;
    });

    useEffect(() => {
        const monthlySummeryData = monthlyData.filter(entry => {
            const dateObject = new Date(entry.date);
            return dateObject.getMonth() === parseInt(filterbyMonth);
        });
        setQaSummeryData(monthlySummeryData);
    }, [filterbyMonth, monthlyData]);

    useEffect(() => {
        fetchMonthlyData();
        fetchQaSummaryData();
        fetchSickLeaveData();
    }, [filterbyMonth]);

    const fetchMonthlyData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/qa-report/${user.displayName}`);
            const data = await response.json();
            setMonthlyData(data);
        } catch (error) {
            console.error('Error fetching monthly data:', error);
            setMonthlyData([])
        }
    };

    const fetchQaSummaryData = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/employee/qa-summery-monthly/${user.displayName}/${filterbyMonth}`);
            const data = await response.json();
            setQaMSummeryData(data);
        } catch (error) {
            console.error('Error fetching QA summary data:', error);
            setQaMSummeryData({});
        } finally {
            setIsLoading(false);
        }
    };

    const fetchSickLeaveData = async () => {
        try {
            // const response = await fetch(`http://localhost:5000/employee/sick-leave/${user.displayName}/${filterbyMonth}`);
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/employee/sick-leave/${user.displayName}/${filterbyMonth}`);
            const data = await response.json();
            setSickLeaveData(data);
        } catch (error) {
            console.error('Error fetching QA summary data:', error);
            setSickLeaveData([])
        } finally {
            setIsLoading(false);
        }
    };

    // ---------------------------------------
    const calculateRatio = () => {
        // console.log(qaMSummeryData);
        if (qaMSummeryData) {
            const newCount = parseInt(qaMSummeryData.new);
            const renewCount = parseInt(qaMSummeryData.renew);
            const total = newCount + renewCount;
            return total !== 0 ? Math.round((newCount / total) * 100) : 0;
        } else {
            return 0;
        }
    };

    const calculateNewFunding = () => {
        return parseInt(qaMSummeryData?.new) || 0
    }
    const calculateReNewFunding = () => {
        return parseInt(qaMSummeryData?.renew) || 0
    }
    const totalSales = (eachEmployee) => {
        return parseInt(qaMSummeryData.sales || 0)
    }
    const calculateTotalFunding = () => {
        return (parseInt(qaMSummeryData.new || 0) + parseInt(qaMSummeryData.renew || 0))
    }
    const totallSickLeave = () => {
        return (sickLeaveData.length) * 10 || 0
    }

    const getTotalCallsByEmployee = () => {
        return qaSummeryData.reduce((total, entry) => total + entry.calls, 0);
    };

    const calculateRating = () => {

        //calculate the funded percentage - max (30%)
        const totalNewAndRenew = calculateTotalFunding(qaMSummeryData);
        const totallFunded = totalNewAndRenew > 1000000 ? 1000000 : totalNewAndRenew;
        const totalFundedPercentage = (100 / 1000000) * totallFunded;
        const totalPercentageForRating = (30 / 100) * totalFundedPercentage;

        const ratio = calculateRatio(qaMSummeryData);
        const maxRatio = ratio > 80 ? 80 : ratio;
        const ratioPercentage = (100 / 80) * maxRatio;
        const ratioPercentageForRating = (40 / 100) * ratioPercentage;

        const totalCalls = getTotalCallsByEmployee();
        const maxCalls = totalCalls > 12000 ? 12000 : totalCalls;
        const callPercentage = (100 / 12000) * maxCalls;
        const callPercentageForRating = (30 / 100) * callPercentage

        const sickLeave = totallSickLeave(qaMSummeryData);

        // Calculate total rating
        const totalRating = (totalPercentageForRating + callPercentageForRating + ratioPercentageForRating) - sickLeave;

        // Ensure the total rating does not exceed 100%
        return Math.min(Math.floor(totalRating), 100);
    };

    const calculateQARating = (tc, ts, sickLeaves) => {
        const maxCallRating = 50;
        const maxSalesRating = 50;

        const totalCalls = tc;
        const sales = ts;
        // const totalCalls = tc > 2000 ? 2000 : tc;
        // const sales = ts > 1000000 ? 1000000 : ts;

        // Cap the call and sales ratings at their maximum percentages
        const callRating = (totalCalls / 2000) * maxCallRating;
        const salesRating = (sales / 500000) * maxSalesRating;
        const sickLeaveDeduction = sickLeaves * 5;

        let qaRating = callRating + salesRating - sickLeaveDeduction;

        // Ensure the QA Rating is not negative
        if (qaRating < 0) {
            qaRating = 0;
        }

        return Math.floor(qaRating);
    };

    function calculateBonus(rating) {
        const points = rating;

        if (points < 100) {
            return 0;
        } else if (points >= 100 && points < 120) {
            return 100;
        } else if (points >= 120 && points < 140) {
            return 200;
        } else if (points >= 140 && points < 160) {
            return 300;
        } else if (points >= 160 && points < 180) {
            return 400;
        } else if (points >= 180 && points < 200) {
            return 500;
        } else if (points >= 200 && points < 220) {
            return 600;
        } else if (points >= 220 && points < 240) {
            return 700;
        } else if (points >= 240 && points < 260) {
            return 800;
        } else if (points >= 260 && points < 280) {
            return 900;
        } else if (points >= 280) {
            return 1000;
        } else {
            return 0;
        }
    }

    if (isLoading) {
        return (
            <div className='font-bold text-4xl flex h-screen justify-center items-center'>
                <PacmanLoader color="#e63a27" />
            </div>
        );
    }

    return (
        <div className='mt-5'>
            <div className='bg-base-200 p-4 rounded-lg flex justify-between items-center'>
                <div>
                    <p className='text-4xl font-bold'>QA Bonus:</p>
                </div>
                <div className='flex gap-5 text-sm'>
                    <div>
                        <p>Name: {user.displayName}</p>
                        <p>Total Calls: {getTotalCallsByEmployee(user.displayName)}</p>
                        <p>Sales: £ {(totalSales()).toLocaleString()}</p>
                    </div>
                    <div>
                        <p>QA Rating: {calculateQARating(parseInt(getTotalCallsByEmployee(user.displayName)), parseInt(totalSales()), sickLeaveData.length)}%</p>
                        <p>QA Bonus: {calculateBonus()}</p>
                        <p>Sick Leaves: {sickLeaveData.length}</p>
                    </div>
                </div>
            </div>
            <div className='mt-3'>
                <div className='pl-0 pb-2 flex justify-end gap-5'>
                    <div>
                        <input
                            type="date"
                            value={fromDate}
                            onChange={handleFromDateChange}
                            className='input input-sm bg-base-200'
                        />
                    </div>
                    <div>
                        <input
                            type="date"
                            value={toDate}
                            onChange={handleToDateChange}
                            className='input input-sm bg-base-200'
                        />
                    </div>
                    <div>
                        <select onChange={onFilterByMonthChange} name='month' className="input input-sm mr-3 w-auto border-2 border-base-200 rounded-lg text-xs">
                            <option value={"2"} defaultValue>Default - {currentMonthName}</option>
                            {monthNames.map((month, index) => (
                                <option key={index} value={index.toString()}>{month}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className='border border-base-300 rounded-lg text-sm h-[calc(100vh-290px)] overflow-auto'>
                    <div className='grid grid-cols-2 bg-base-200 divide-x divide-base-300'>
                        <span className='p-3'>Date</span>
                        <span className='p-3'>Calls</span>
                    </div>
                    <div className='divide-y divide-base-300'>
                        {filteredData.map((row, i) => (
                            <div key={i} className='grid grid-cols-2 divide-x divide-base-300 hover:bg-base-200 cursor-pointer'>
                                <span className='p-3'>{new Date(row.date).toLocaleDateString('en-GB', {
                                    day: 'numeric',
                                    month: 'numeric',
                                    year: 'numeric',
                                })}</span>
                                <span className='p-3'>{row.calls}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QaBonusStaff;
